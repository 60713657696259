<template>
  <div class="driver-card-mobile">
    <div class="driver-card-mobile__group">
      <p class="driver-card-mobile__text">
        {{ driver.name }} {{ driver.surname }}
      </p>
      <div class="driver-card-mobile__description">
        <p>{{ driver.phone_number }} • {{ driver.iin }}</p>
        <p v-if="driver.comment">{{ driver.comment }}</p>
      </div>
    </div>
    <p>
      Дата добавления:
      {{ dayjs(driver.created_at).locale("ru").format("DD MMM YYYY, HH:mm") }}
    </p>
    <p>Баланс: {{driver.amount}} ₸</p>
    <span
      class="driver-card-mobile__more"
      @click="showOptions = !showOptions"
    ></span>
    <div v-if="showOptions" class="driver-card-mobile__option-wrap">
      <p class="driver-card-mobile__option" @click="showDetails = true">
        Редактировать
      </p>
      <p class="driver-card-mobile__option" @click="showTransactions = true">
        Посмотреть транзакции
      </p>
    </div>

    <UiModal
      v-if="showDetails || showTransactions"
      :title="showTransactions ? 'Транзакции' : 'Редактировать водителя'"
      @overlayClick="hideOptions"
      is-bg-white
    >
      <DriversDetailsModal
        v-if="showDetails"
        :is-mobile="true"
        :show-transactions="false"
        :driverId="driver.id"
        @close="showDetails = false"
      />
      <DriversDetailsModal
        v-else-if="showTransactions"
        :is-mobile="true"
        :show-transactions="true"
        :driverId="driver.id"
        @close="showDetails = false"
      />
    </UiModal>
  </div>
</template>

<script>
import UiModal from "@/components/ui/UiModal.vue";
import DriversDetailsModal from "@/components/drivers/DriversDetailsModal.vue";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "DriverCardMobile",
  components: {
    UiModal,
    DriversDetailsModal,
  },
  props: {
    driver: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showOptions: false,
      showDetails: false,
      showTransactions: false,
    };
  },
  methods: {
    hideOptions() {
      this.showDetails = false;
      this.showTransactions = false;
      this.showOptions = false;
    },
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.driver-card-mobile {
  position: relative;
  margin: 0 12px;
  padding: 16px 0;
  border-bottom: 1px solid #dadfe5;
  font-size: 14px;
  line-height: 18px;

  &__description {
    color: #9ea6b4;
  }
  &__text {
    margin-bottom: 4px;
  }
  &__group {
    margin-bottom: 12px;
  }
  &__more {
    position: absolute;
    top: 16px;
    right: 0;
    width: 24px;
    height: 24px;
    background: url("~@/assets/images/icons/more.svg") center / contain
      no-repeat;
    cursor: pointer;
  }
  &__option {
    padding: 9px 12px;
    font-size: 14px;
    line-height: 18px;

    &-wrap {
      position: absolute;
      top: 47px;
      right: 4px;
      padding: 8px 0;
      background: #fff;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2),
        0px 10px 20px rgba(33, 52, 82, 0.2);
      border-radius: 6px;
    }
  }
}
</style>
