export const DeviceModule = {
  namespaced: true,
  state: {
    isMobile: false
  },
  getters: {
    isMobile(state) {
      return state.isMobile;
    },
  },
  mutations: {
    SET_IS_MOBILE(state, bool) {
      state.isMobile = bool;
    },
  },
};
