import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getCookie } from '@/utils/storage/cookies';
// import { logout } from "@/services/api/modules/auth";

const client = axios.create({
  baseURL: process.env.VUE_APP_CHOCO_API_PROXY,
  transformRequest: [data => JSON.stringify(data)],
  responseType: 'json',
});

const xls = axios.create({
  baseURL: process.env.VUE_APP_CHOCO_API_PROXY,
  transformRequest: [data => JSON.stringify(data)],
  responseType: 'arraybuffer',
  headers: {
    Accept: 'application/octet-stream',
  }
});

xls.interceptors.request.use(
  config => {
    config.headers['X-Idempotency-key'] = uuidv4();
    config.headers.Authorization = `Bearer ${getCookie('auth::token')}`;
    return config;
  }
);

client.context = {
  deviceId: undefined,
  sessionId: undefined,
  accessToken: getCookie('auth::token'),
  refreshToken: getCookie('auth::refresh-token'),
  expireIn: getCookie('auth::expire-in')
};

client.interceptors.request.use(
  config => {
    config.headers['X-Idempotency-key'] = uuidv4();
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/vnd.api+json';

    if (getCookie('auth::token')) {
      config.headers.Authorization = `Bearer ${getCookie('auth::token')}`;
    }

    return config;
  },
  err => Promise.reject(err)
);

client.interceptors.response.use(async res => {
  const errorCode = res.data.error_code;
  // const { config } = res;

  if (errorCode === 401) {
    console.log('error', errorCode, res.data);
    return window.location.replace(
      `${process.env.VUE_APP_AUTH_SERVER}/oauth/login?redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT_URI}&client_id=${process.env.VUE_APP_CLIENT_ID}`
    );
    // // возможная логика логаута
  }
  return res;
});

export { client, xls };
