import { api } from '@/services/api/modules';

export const UserModule = {
  namespaced: true,
  state: {
    id: null,
    firstName: null,
    lastName: null,
    phone: null,
    avatar: null,
  },
  getters: {
    userProfile(state) {
      return state;
    },
  },
  mutations: {
    SET_USER_PROFILE(state, value) {
      const {
        id,
        firstName,
        lastName,
        phone,
        avatar
      } = value;
      state.id = id;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phone = phone;
      state.avatar = avatar;
    },

  },
  actions: {
    FETCH_USER_PROFILE({ commit }) {
      return api.user.profile()
        .then(({ data }) => {
          const { id } = data;
          const {
            first_name: firstName,
            last_name: lastName,
            phone,
            avatar
          } = data.attributes;
          commit('SET_USER_PROFILE', {
            id,
            firstName,
            lastName,
            phone,
            avatar,
          });
        });
    },
  }
};
