import Vue from 'vue';
import Vuex from 'vuex';
import { UserModule } from './modules/user';
import { DriversModule } from './modules/drivers/index';
import { PaymentsModule } from './modules/payments';
import { DeviceModule } from './modules/device';
import { CompanyModule } from './modules/company';
import { SummaryModule } from './modules/summary';
import { FilterModule } from './modules/filter';

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: true,
  modules: {
    user: UserModule,
    drivers: DriversModule,
    payments: PaymentsModule,
    device: DeviceModule,
    company: CompanyModule,
    summary: SummaryModule,
    filter: FilterModule
  },
});
