var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isMobile)?_c('div',{staticClass:"filter-period"},[_c('div',{staticClass:"filter-period__group"},[_vm._l((_vm.periods),function(item){return _c('div',{key:item.name,staticClass:"filter-period__item",class:{
        'filter-period__item--active':
          _vm.selectedRange.name === item.name && !_vm.showCalendar,
      },on:{"click":function($event){return _vm.changePeriod(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{staticClass:"filter-period__item",class:{
        'filter-period__item--active':
          _vm.selectedRange.name === 'Выбрать период' || _vm.showCalendar,
      },on:{"click":function($event){_vm.showCalendar = !_vm.showCalendar}}},[_vm._v(" Выбрать период ")])],2),_c('div',{staticClass:"filter-period__range",on:{"click":function($event){_vm.showCalendar = !_vm.showCalendar}}},[_vm._v(" "+_vm._s(_vm.dayjs(_vm.selectedRange.start).locale("ru").format("DD MMM"))+" "),(_vm.selectedRange.start !== _vm.selectedRange.end)?_c('span',[_vm._v(" - "+_vm._s(_vm.dayjs(_vm.selectedRange.end).locale("ru").format("DD MMM"))+" ")]):_vm._e()]),(_vm.showCalendar)?_c('CalendarDropdown',{staticClass:"filter-period__calendar",attrs:{"calendar-marked-dates":{
      start: _vm.vfcFormat(_vm.selectedRange.start),
      end: _vm.vfcFormat(_vm.selectedRange.end),
    }},on:{"changeRange":_vm.selectPeriod}}):_vm._e()],1):(_vm.isMobile && !_vm.showCalendar)?_c('div',{staticClass:"filter-period-mobile"},[_vm._l((_vm.periods),function(item){return _c('div',{key:item.id,staticClass:"filter-period-mobile__period",on:{"click":function($event){_vm.selectedRange = item}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRange),expression:"selectedRange"}],attrs:{"id":item.id,"type":"radio"},domProps:{"value":item,"checked":_vm._q(_vm.selectedRange,item)},on:{"change":function($event){_vm.selectedRange=item}}}),_c('label',{attrs:{"for":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"filter-period-mobile__date"},[_vm._v(" "+_vm._s(_vm.dayjs(item.start).locale("ru").format("DD MMM"))+" "),(item.start != item.end)?_c('span',[_vm._v(" — "+_vm._s(_vm.dayjs(item.end).locale("ru").format("DD MMM"))+" ")]):_vm._e()])])}),_c('div',{staticClass:"filter-period-mobile__period"},[_c('input',{attrs:{"id":"calendar","type":"radio"},domProps:{"checked":_vm.selectedRange.name === 'Выбрать период'}}),_c('label',{staticClass:"cursor-pointer filters-list__label",attrs:{"for":"calendar"},on:{"click":function($event){_vm.showCalendar = true}}},[_vm._v(" Выбрать период ")])]),_c('div',{staticClass:"mobile-footer"},[_c('UiButton',{staticClass:"mobile-full-btn",on:{"click":_vm.saveData}},[_vm._v("Применить")])],1)],2):(_vm.isMobile && _vm.showCalendar)?_c('CalendarDropdown',{staticClass:"filter-period__calendar",attrs:{"calendar-marked-dates":{
    start: _vm.vfcFormat(_vm.selectedRange.start),
    end: _vm.vfcFormat(_vm.selectedRange.end),
  }},on:{"changeRange":_vm.selectPeriod}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }