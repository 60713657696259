<template>
  <div class="drivers-add-modal">
    <div v-if="isLoading" class="loader-overlay" @click.prevent="">
      <UiLoader class="loader" />
    </div>
    <div class="drivers-add-modal__header">
      <h1 class="drivers-add-modal__title">Новый водитель</h1>
      <span class="drivers-add-modal__cross" @click="$emit('close')" />
    </div>
    <div class="drivers-add-modal__form">
      <DriversForm @change="changeData" :validating="isValidating" />
    </div>
    <div v-if="errors.length" class="errors">
      <div v-for="(error, i) in errors" :key="i" class="errors__text">
        {{ error.detail }}
      </div>
    </div>
    <div class="drivers-add-modal__footer">
      <UiButton is-full @click="createNewDriver">Добавить водителя</UiButton>
    </div>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiButton from "@/components/ui/UiButton.vue";
import DriversForm from "@/components/drivers/DriversForm.vue";

export default {
  name: "DriversAddModal",
  components: {
    UiButton,
    UiLoader,
    DriversForm,
  },
  data() {
    return {
      driver: null,
      selectedCity: 1,
      isLoading: false,
      isValidating: false,
      errors: [],
    };
  },
  computed: {
    hasError() {
      return (
        this.isValidating &&
        (!this.driver?.surname ||
          !this.driver?.city ||
          !this.driver?.name ||
          !this.driver?.phone ||
          !this.driver?.iin)
      );
    },
  },
  methods: {
    createNewDriver() {
      this.isValidating = true;
      if (this.hasError) {
        return;
      }
      this.isLoading = true;
      api.drivers
        .createDriver({
          city_id: this.selectedCity,
          name: this.driver.name,
          surname: this.driver.surname,
          phone_number: this.driver?.phone.replace(/\s/g, ""),
          iin: this.driver.iin,
          comment: this.driver.comment,
          details: this.driver.details,
        })
        .then((res) => {
          if (res.data?.errors.length) {
            console.log(res.data);
            this.errors = res.data.errors;
          } else {
            this.$emit("close");
            this.$emit("refreshList");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeData(value) {
      this.driver = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.errors {
  padding: 20px;
  color: var(--red);

  @media (max-width: 1024px) {
    background: #fff;
  }

  &__text {
    margin-bottom: 8px;
  }
}
.drivers-add-modal {
  position: relative;
  &__title {
    font-size: 24px;
    line-height: 30px;
    padding: 25px 20px 16px 20px;
  }
  &__cross {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 18px;
    height: 18px;
    background: url("~@/assets/images/icons/cross.svg");
    cursor: pointer;
  }
  &__form {
    padding: 0 20px;
  }
  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 528px;
    padding: 12px 20px;
    background: var(--white);
  }

  @media (max-width: 1024px) {
    &__header {
      display: none;
    }
    &__form {
      padding: 0;
    }
    &__footer {
      position: relative;
      width: 100%;
      padding: 12px;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2),
        0px -4px 10px rgba(33, 52, 82, 0.04);
    }
  }
}
</style>
