<template>
  <div class="ui-switcher">
    <div
      v-for="item in items"
      :key="item.name"
      class="ui-switcher__item"
      :class="{ 'ui-switcher__item--active': item.id === selectedId }"
      @click="switchVal(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "UiSwitcher",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedId: this.items[0].id,
    };
  },
  methods: {
    switchVal(item) {
      this.selectedId = item.id;
      this.$emit("switch", this.selectedId);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-switcher {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px;
  background: var(--secondary-normal);

  &__item {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 18px;
    margin-right: 4px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      background: var(--white);
      border-radius: 4px;
    }
  }
}
</style>
