<template>
  <div class="payment-sort">
    <button
      v-if="!isMobile"
      class="payment-sort__btn"
      :class="{
        'payment-sort__btn--rotate': showDropdown,
        'payment-sort__btn--rounded': selectedSort.value,
      }"
      @click="toggleDropdown"
    >
      К выплате

      <span v-if="selectedSort.trend === 'more' && selectedSort.value"
        >&gt;</span
      >
      <span v-else-if="selectedSort.trend === 'less' && selectedSort.value"
        >&lt;</span
      >

      {{ selectedSort.value }}
    </button>

    <div
      v-if="selectedSort.value"
      class="payment-sort__clear"
      @click="resetFilter"
    ></div>
    <div
      v-if="showDropdown"
      class="payment-sort__dropdown"
      :class="{ 'payment-sort__dropdown--mobile': isMobile }"
    >
      <div class="flex" :class="{ 'payment-sort__group': isMobile }">
        <UiSwitcher
          :items="sortArray"
          class="payment-sort__item"
          @switch="switchSort"
        />
        <UiInput
          :is-cost="true"
          class="payment-sort__item"
          @input="inputValue"
        />
      </div>
      <UiButton :is-full="isMobile" @click="accept"> Применить </UiButton>
    </div>
  </div>
</template>

<script>
import UiSwitcher from "@/components/ui/UiSwitcher.vue";
import UiInput from "@/components/ui/UiInput.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "PaymentsSort",
  components: {
    UiSwitcher,
    UiInput,
    UiButton,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.isMobile) {
      this.showDropdown = true;
    }
  },
  data() {
    return {
      showDropdown: false,
      sortArray: [
        { id: 1, name: "Больше", trend: "more" },
        { id: 2, name: "Меньше", trend: "less" },
      ],
      selectedSort: {
        value: "",
        trend: "more",
      },
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    accept() {
      this.showDropdown = false;
      this.$emit("sort", this.selectedSort);
    },
    switchSort(id) {
      this.selectedSort.trend = this.sortArray.find(
        (item) => item.id === id
      ).trend;
    },
    inputValue(val) {
      this.selectedSort.value = val;
    },
    resetFilter() {
      this.selectedSort.value = "";
      this.selectedSort.trend = "more";
      this.showDropdown = false;
      this.$emit("sort", this.selectedSort);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-sort {
  position: relative;
  display: flex;

  &__btn {
    position: relative;
    padding: 2px 36px 2px 12px;
    background: var(--secondary-normal);
    border-radius: 6px;
    font-size: 14px;
    line-height: 36px;

    &:after {
      content: "";
      position: absolute;
      top: 17px;
      right: 15px;
      width: 10px;
      height: 6px;
      background: url("~@/assets/images/icons/arrow-down.svg");
      transition: transform 0.2s;
    }
    &--rotate {
      &:after {
        transform: rotate(180deg);
      }
    }
    &--rounded {
      border-radius: 6px 0 0 6px;
    }
  }

  &__clear {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 2px;
    background: #d9e1eb;
    border-radius: 0px 6px 6px 0px;
    cursor: pointer;

    &:after {
      position: absolute;
      content: "";
      top: 12px;
      left: 12px;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/close.svg");
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    padding: 16px;
    display: flex;
    background: var(--white);
    box-shadow: 0px 6px 12px rgba(17, 24, 56, 0.15),
      0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    &--mobile {
      position: relative;
      display: block;
    }
  }

  &__item {
    margin-right: 8px;
  }

  &__group {
    margin-bottom: 24px;
  }
}
</style>
