<template>
  <div class="ui-dropdown__container">
    <p v-if="label" class="ui-dropdown__label">{{ label }}</p>
    <div
      class="ui-dropdown"
      @click="toggleDropdown"
      :class="{ 'ui-dropdown--error': hasError }"
    >
      <span :class="{ 'ui-dropdown--disabled': !selectedItem }">{{
        selectedItem || "Выберите..."
      }}</span>
      <div v-if="showDropdown" class="ui-dropdown__list">
        <div
          v-for="item in list"
          :key="item.id"
          class="ui-dropdown__item"
          @click="selectItem(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiDropdown",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectItem(item) {
      this.selectedItem = item.name;
    },
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-dropdown {
  position: relative;
  border: 1px solid var(--border);
  border-radius: 6px;
  padding: 11px;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 6px;
    background: url("~@/assets/images/icons/arrow-down.svg");
    transform: translateY(-50%);
  }

  &--disabled {
    color: var(--secondary-dark);
  }

  &--error {
    border: 1px solid var(--red);
  }

  &__list {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    padding: 0.5rem 0;
    background: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2),
      0px 10px 20px rgba(33, 52, 82, 0.2);
    border-radius: 6px;
  }

  &__label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  &__item {
    padding: 11px;

    &:hover {
      background: var(--bg-primary);
    }
  }
}
</style>
