<template>
  <button
    class="ui-button"
    :class="{
      'ui-button--full': isFull,
      'ui-button--grey': isGrey,
      'ui-button--disabled': isDisabled,
    }"
    @click="$emit('click')"
  >
    <slot v-eles></slot>
  </button>
</template>

<script>
export default {
  name: "UiButton",
  props: {
    isFull: {
      type: Boolean,
      default: false,
    },
    isGrey: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-button {
  padding: 10px 18px;
  border-radius: 8px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  background: var(--green);

  &--full {
    width: 100%;
  }
  &--disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &--grey {
    background: #d9e1eb;
    color: #2f3747;
  }
}
</style>
