<template>
  <div class="summary-couriers">
    <h1 class="summary-couriers__title">Активные курьеры</h1>
    <UiQuestionIcon @click.native="showHelp = !showHelp" />
    <UiHelper v-if="showHelp" class="summary-couriers__question">
      Число уникальных водителей,
      <span style="white-space: nowrap">
        у которых был заказ за этот период
      </span>
    </UiHelper>
    <div>
      <canvas id="summary-courier" height="210"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import UiQuestionIcon from "@/components/ui/UiQuestionIcon.vue";
import UiHelper from "@/components/ui/UiHelper.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "SummaryCouriers",
  components: {
    UiQuestionIcon,
    UiHelper,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showHelp: false,
    };
  },
  computed: {
    ...mapGetters({ getActiveCouriers: "summary/getActiveCouriers" }),
    chartData() {
      return {
        type: "line",
        data: {
          labels: this.getActiveCouriers.map((item) => {
            return dayjs(item.datetime).locale("ru").format("DD MMM");
          }),
          datasets: [
            {
              data: this.getActiveCouriers.map((item) => item.count),
              borderColor: "#47C76B",
              backgroundColor: "#47c76b1a",
              fill: true,
            },
          ],
        },
        options: {
          plugins: {
            legend: false,
            title: {
              display: true,
              text: "Chart.js Line Chart - Cubic interpolation mode",
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Value",
              },
              suggestedMin: -10,
              suggestedMax: 200,
            },
          },
        },
      };
    },
  },
  async mounted() {
    const ctx = document.getElementById("summary-courier");
    await new Chart(ctx, this.chartData);
  },
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.summary-couriers {
  position: relative;
  &__title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }
  &__question {
    position: absolute;
    width: 292px;
    right: 26px;
  }
}
</style>
