<template>
  <div class="ui-serach-bar relative">
    <input
      v-model="search"
      type="text"
      placeholder="Поиск по водителям..."
      class="ui-serach-bar__input"
      @input="searchResults"
    />
    <figure v-if="search" class="ui-serach-bar__cross" @click="clearSearch">
      <img src="@/assets/images/icons/cross.svg" />
    </figure>
  </div>
</template>

<script>
export default {
  name: "UiSearchBar",
  data() {
    return {
      search: "",
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.searchResults();
    },
    searchResults() {
      this.$emit("change", this.search);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-serach-bar {
  position: relative;
  width: 280px;
  border: 1px solid #dadfe5;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background: var(--white);
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 12px;
    width: 16px;
    height: 16px;
    background: url("~@/assets/images/icons/search.svg");
  }
  &__input {
    padding: 10px 0 10px 36px;
  }
  &__cross {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    &__input {
      padding-right: 36px;
      width: 100%;
    }
  }
}
</style>
