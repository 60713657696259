<template>
  <div class="ui-tab-list">
    <ul class="ui-tab-list__container">
      <li
        v-for="(tab, i) in tabs"
        :key="i"
        class="ui-tab-list__item ui-tab-list__title"
        :class="{ 'ui-tab-list__item--active': tab.isActive }"
        @click="selectTab(tab)"
      >
        {{ tab.name }}
      </li>
    </ul>
    <div class="ui-tab-list__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiTabList",
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(this.tabs[0]);
  },
  methods: {
    selectTab(newTab) {
      if (newTab.callback) {
        newTab.callback();
      }
      this.tabs.forEach((tab) => (tab.isActive = tab.name === newTab.name));
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-tab-list {
  &__container {
    display: flex;
    background: var(--white);
    padding: 0 20px;
  }
  &__item {
    margin-right: 25px;
    margin-bottom: 12px;
    color: var(--secondary-dark);
    cursor: pointer;

    :last-child {
      margin-right: 0;
    }
    &--active {
      position: relative;
      color: var(--primary-dark);

      &:after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: 10px;
        background: var(--primary-dark);
      }
    }
  }
  &__title {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
