<template>
  <div v-if="!isMobile" class="filter-period">
    <div class="filter-period__group">
      <div
        v-for="item in periods"
        :key="item.name"
        class="filter-period__item"
        :class="{
          'filter-period__item--active':
            selectedRange.name === item.name && !showCalendar,
        }"
        @click="changePeriod(item)"
      >
        {{ item.name }}
      </div>
      <div
        class="filter-period__item"
        :class="{
          'filter-period__item--active':
            selectedRange.name === 'Выбрать период' || showCalendar,
        }"
        @click="showCalendar = !showCalendar"
      >
        Выбрать период
      </div>
    </div>
    <div class="filter-period__range" @click="showCalendar = !showCalendar">
      {{ dayjs(selectedRange.start).locale("ru").format("DD MMM") }}
      <span v-if="selectedRange.start !== selectedRange.end">
        - {{ dayjs(selectedRange.end).locale("ru").format("DD MMM") }}
      </span>
    </div>

    <CalendarDropdown
      v-if="showCalendar"
      :calendar-marked-dates="{
        start: vfcFormat(selectedRange.start),
        end: vfcFormat(selectedRange.end),
      }"
      class="filter-period__calendar"
      @changeRange="selectPeriod"
    />
  </div>
  <!-- mobile list -->
  <div v-else-if="isMobile && !showCalendar" class="filter-period-mobile">
    <div
      v-for="item in periods"
      :key="item.id"
      class="filter-period-mobile__period"
      @click="selectedRange = item"
    >
      <input :id="item.id" v-model="selectedRange" type="radio" :value="item" />
      <label :for="item.id">
        {{ item.name }}
      </label>
      <span class="filter-period-mobile__date">
        {{ dayjs(item.start).locale("ru").format("DD MMM") }}
        <span v-if="item.start != item.end">
          — {{ dayjs(item.end).locale("ru").format("DD MMM") }}
        </span>
      </span>
    </div>
    <div class="filter-period-mobile__period">
      <input
        id="calendar"
        type="radio"
        :checked="selectedRange.name === 'Выбрать период'"
      />
      <label
        for="calendar"
        class="cursor-pointer filters-list__label"
        @click="showCalendar = true"
      >
        Выбрать период
      </label>
    </div>
    <div class="mobile-footer">
      <UiButton class="mobile-full-btn" @click="saveData">Применить</UiButton>
    </div>
  </div>
  <!-- mobile calendar -->
  <CalendarDropdown
    v-else-if="isMobile && showCalendar"
    :calendar-marked-dates="{
      start: vfcFormat(selectedRange.start),
      end: vfcFormat(selectedRange.end),
    }"
    class="filter-period__calendar"
    @changeRange="selectPeriod"
  />
</template>

<script>
// import UiRadio from "@/components/ui/UiRadio.vue";
import UiButton from "@/components/ui/UiButton.vue";
import { dateFilters } from "@/utils/constants/date-filter.constant";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import CalendarDropdown from "./CalendarDropdown.vue";

require("dayjs/locale/ru");

export default {
  name: "FilterPeriod",
  components: {
    CalendarDropdown,
    // UiRadio,
    UiButton,
  },
  data() {
    return {
      periods: dateFilters,
      selectedRange: dateFilters[1],
      showCalendar: false,
      calendarData: {},
    };
  },
  computed: {
    ...mapGetters({ isMobile: "device/isMobile" }),
  },
  methods: {
    selectPeriod(date) {
      const startDate = this.toUtcFromVfc(date.start);
      const endDate = this.toUtcFromVfc(date.end);

      this.selectedRange = {
        name: "Выбрать период",
        start: startDate,
        end: endDate,
      };
      this.$emit("change", this.selectedRange);
      this.showCalendar = false;
      this.$emit("close");
    },
    changePeriod(item) {
      this.selectedRange = item;
      this.$emit("change", item);
      this.showCalendar = false;
    },
    toUtcFromVfc(dates) {
      const arr = dates.split("/");
      arr.reverse();
      const month = arr[1];
      const date = arr[2];
      arr[1] = month.length === 1 ? `0${month}` : month;
      arr[2] = date.length === 1 ? `0${date}` : date;

      return arr.join("-");
    },
    vfcFormat(dates) {
      const arr = dates.split("-");
      const month = arr[1];
      const date = arr[2];
      arr[1] = month.length === 2 && month[0] === "0" ? month[1] : month;
      arr[2] = date.length === 2 && date[0] === "0" ? date[1] : date;
      arr.reverse();
      return arr.join("/");
    },
    dayjs,
    saveData() {
      this.$emit("close");
      this.$emit("change", this.selectedRange);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-period {
  display: flex;
  &__group {
    display: flex;
    padding: 4px;
    border-radius: 6px;
    margin-right: 0.5rem;
    font-size: 14px;
    line-height: 18px;
    background: var(--bg-secondary);
  }
  &__item {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    &--active {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background: var(--white);
      cursor: default;
    }
  }

  &__range {
    position: relative;
    padding: 11px 12px 11px 36px;
    border-radius: 6px;
    margin-right: 0.5rem;
    font-size: 14px;
    line-height: 18px;
    background: var(--bg-secondary);
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 13px;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/calendar.svg");
    }
  }
  &__calendar {
    position: absolute;
    top: calc(100% + 4px);
    left: 30px;
    padding: 12px 24px;
    background: var(--white);
    z-index: 5;
    box-shadow: 0px 6px 12px rgba(17, 24, 56, 0.15),
      0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  @media (max-width: 1185px) {
    &__group {
      display: none;
    }
  }
  @media (max-width: 520px) {
    &__calendar {
      position: relative;
      top: 0;
      left: 0;
      padding: 12px;
      border-radius: 0;
      box-shadow: unset;
    }
  }
}
input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  & + label::before {
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--border);
    border-radius: 50%;
  }

  &:checked + label::before {
    opacity: 1;
    transform: scale(0.5);
    background: var(--green);
  }

  &:checked + label::after {
    position: absolute;
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--green);
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.filter-period-mobile {
  background: #fff;

  &__period {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    &:nth-child(4):after {
      content: "";
      position: absolute;
      top: 0;
      height: 1px;
      background: var(--border);
      width: calc(100% - 32px);
    }
  }

  &__date {
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-dark);
  }
}
</style>
