<template>
  <div class="ui-question-icon"></div>
</template>

<script>
export default {
  name: "UiQuestionIcon",
};
</script>

<style lang="scss" scoped>
.ui-question-icon {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
  background: url("~@/assets/images/icons/question.svg");
  cursor: pointer;

  @media (max-width: 1024px) {
    top: 19px;
  }
}
</style>
