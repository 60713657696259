<template>
  <div class="flex flex-column ui-input">
    <span v-if="label" class="ui-input__label">{{ label }}</span>
    <input
      v-model="model"
      type="text"
      class="ui-input__container"
      :maxlength="maxLength"
      :disabled="disabled"
      :class="{
        'ui-input__container--disabled': disabled,
        'ui-input__container--cost': isCost,
        'ui-input__container--error': hasError,
      }"
    />
    <span v-if="hasError" class="ui-input__error">{{ errorText }}</span>
  </div>
</template>

<script>
export default {
  name: "UiInput",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      required: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCost: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 96,
    },
  },
  data() {
    return {
      errorText: "Заполните поле",
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-input {
  &__label {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
  }
  &__container {
    border: 1px solid var(--border);
    border-radius: 6px;
    padding: 11px;
    font-size: 14px;
    line-height: 18px;

    &--disabled {
      background: var(--bg-primary);
      border: 1px solid var(--bg-primary);
      color: var(--secondary-dark);
    }

    &--cost {
      position: relative;
      width: 120px;
      padding-right: 15px;

      &:after {
        position: absolute;
        right: 0;
        content: "T";
      }
    }

    &--error {
      border: 1px solid var(--red);
    }
  }
  &__error {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
    color: var(--red);
  }
}
</style>
