export function getToday() {
  const today = new Date();
  return today.toISOString().slice(0, 10);
}

export function getYesterday() {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));
  return yesterday.toISOString().slice(0, 10);
}

export function getWeek() {
  const today = new Date();
  const weekAgo = new Date(today.setDate(today.getDate() - 7));
  return weekAgo.toISOString().slice(0, 10);
}

export function getOneMonth() {
  const today = new Date();
  const monthAgo = new Date(today.setMonth(today.getMonth() - 1));
  return monthAgo.toISOString().slice(0, 10);
}

export const dateFilters = [
  {
    id: 0, name: 'Вчера', start: `${getYesterday()}`, end: `${getYesterday()}`
  },
  {
    id: 1, name: 'Неделя', start: `${getWeek()}`, end: `${getToday()}`
  },
  {
    id: 2, name: 'Месяц', start: `${getOneMonth()}`, end: `${getToday()}`
  },
];

export const fromTodayOneMonthPeriod = {
  id: 3, name: 'Сегодня', start: `${getOneMonth()}`, end: `${getToday()}`
};
