<template>
  <div class="not-found">
    <img
      src="@/assets/images/coms-centered-logo.svg"
      class="not-found__img"
      alt="logo"
    />
    <div class="not-found__title">Таксопарк не найден</div>
    <button class="not-found__btn" @click="doLogout">Выйти из аккунта</button>
  </div>
</template>

<script>
import { logout } from "@/services/api/modules/auth";

export default {
  name: "CompanyNotFound",
  methods: {
    doLogout() {
      logout()
        .then(() => {
          window.location.replace(
            `${process.env.VUE_APP_AUTH_SERVER}/oauth/logout`
          );
        })
        .catch(() => this.toast.show("Произошла ошибка при выходе с аккаунта"));
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__wrap {
    display: flex;
  }

  &__title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 32px;
  }

  &__btn {
    display: inline-block;
    border-radius: 8px;
    padding: 10px 29px;
    font-size: 16px;
    line-height: 20px;
    background: #d9e1eb;
  }

  &__img {
    position: absolute;
    top: 57px;
  }
}
</style>
