// import { api } from '@/services/api';
import axios from "axios";

export const DriversModule = {
  namespaced: true,
  state: {
    drivers: [],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    FETCH_DRIVERS_LIST({ commit }, payload) {
      let URL = `${process.env.VUE_APP_API_PREFIX}/v1/employees?`;
      if (payload) {
        if (payload.name) {
          URL += `filter[driver]=${payload.name}&`;
        }
        if (payload.date) {
          URL += `filter[date_from]=${payload.date.from}&filter[date_to]=${payload.date.to}`;
        }
      }

      console.log(URL);
      return axios.get(URL)
        .then(response => {
          commit('SET_DRIVERS_LIST', response.data.data.items);
          return response.data;
        })
        .catch(err => err);
    },
  }
};
