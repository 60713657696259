import Vue from 'vue';
import FunctionalCalendar from 'vue-functional-calendar';
import VueObserveVisibility from 'vue-observe-visibility';
import App from './App.vue';
import router from './router';
import { store } from './store';

import '@/assets/styles/main.css';
import '@/assets/styles/vendor/calendar.scss';

Vue.use(VueObserveVisibility);

Vue.use(FunctionalCalendar, {
  dayNames: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
