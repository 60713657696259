<template>
  <div class="payment-card">
    <UICheckbox
      :array="getBulkPaymentsList"
      :value="paymentData.employee_id"
      v-if="!isCompletedPayment"
      @change="$emit('bulkPayment', paymentData)"
    >
      <div>
        {{ paymentData.employee_name }}
        <p class="payment-card__phone">
          {{ paymentData.phone_number }}
        </p>
      </div>
    </UICheckbox>
    <div v-else>
      <div>{{ paymentData.employee_name }}</div>
      <div class="payment-card__description">
        № {{ paymentData.transaction_id }} •
        {{ dayjs(paymentData.created_at).locale("ru").format("DD MMM, HH:mm") }}
      </div>
    </div>
    <div>
      <p class="payment-card__amount">{{ paymentData.amount }} ₸</p>
      <p
        v-if="!isCompletedPayment"
        class="payment-card__button"
        @click="$emit('showModal', paymentData)"
      >
        Выплатить
      </p>
      <p v-else class="payment-card__description">Наличными</p>
    </div>
  </div>
</template>

<script>
import UICheckbox from "@/components/ui/UiCheckbox.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "PaymentsCardMobile",
  components: {
    UICheckbox,
  },
  props: {
    paymentData: {
      type: Object,
      default: () => {},
    },
    isCompletedPayment: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getBulkPaymentsList: "payments/getBulkPaymentsList",
    }),
  },
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.payment-card {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin: 0 12px;
  font-size: 14px;
  line-height: 18px;

  &__description {
    font-size: 14px;
    line-height: 18px;
    color: #9ea6b4;
  }

  &__phone {
    margin-top: 2px;
    color: #9ea6b4;
  }

  &__amount {
    text-align: end;
  }
  &__button {
    margin-top: 2px;
    color: #4caf50;
    text-align: end;
    cursor: pointer;
  }
}
</style>
