export const CompanyModule = {
  namespaced: true,
  state: {
    isNotFound: false
  },
  getters: {
    isNotFound(state) {
      return state.isNotFound;
    },
  },
  mutations: {
    SET_COMPANY_NOT_FOUND(state, bool) {
      state.isNotFound = bool;
    }
  },
};
