<template>
  <div class="payments-form">
    <UiInput
      v-model="payment.name"
      label="Водитель"
      :has-error="false"
      :disabled="notEditable"
      class="payments-form__block"
      @input="searchDriverName"
    />

    <div v-if="driversList.length && showList" class="payments-form__list">
      <p
        v-for="driver in driversList"
        :key="driver.id"
        class="payments-form__list-item"
        @click="selectDriver(driver)"
      >
        {{ driver.name }} {{ driver.surname }}
      </p>
    </div>

    <div class="payments-form__group payments-form__block">
      <div class="payments-form__payments">
        <p class="payments-form__title">Способ оплаты</p>
        <UiSwitcher :items="paymentTypes" />
      </div>
      <UiInput
        v-model="payment.sum"
        :disabled="notEditable"
        label="Сумма выплаты"
        class="payments-form__sum"
        @input="setAmount"
      />
    </div>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import UiSwitcher from "@/components/ui/UiSwitcher.vue";
import UiInput from "@/components/ui/UiInput.vue";
import debounce from "debounce";

export default {
  name: "PaymentsForm",
  components: {
    UiInput,
    UiSwitcher,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    notEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payment: {
        name: null,
        sum: "",
      },
      paymentTypes: [
        { id: 1, name: "Наличными" },
        { id: 2, name: "На карту" },
      ],
      driversList: [],
      showList: false,
      selectedDriver: null,
    };
  },

  created() {
    if (this.value) {
      this.payment.name = this.value.employee_name;
      this.payment.sum = this.value.amount.toString();
    }
  },
  watch: {
    payment: {
      handler() {
        this.$emit("change", this.payment);
      },
      deep: true,
    },
  },
  methods: {
    searchDriverName: debounce(function searchDriverName(driver) {
      this.getDriversList(driver);
    }, 400),

    getDriversList(name) {
      api.drivers.getDriversList({ name }).then((res) => {
        this.driversList = res.data?.items;
        this.showList = true;
      });
    },
    selectDriver(driver) {
      this.selectedDriver = driver;
      this.payment.name = `${driver.name} ${driver.surname}`;
      this.showList = false;
      this.$emit("setDriver", driver.id);
    },
    setAmount(val) {
      this.$emit("setAmount", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-form {
  position: relative;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background: var(--white);

  &__block {
    margin-bottom: 16px;
  }

  &__group {
    display: flex;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
  }
  &__payments {
    margin-right: 16px;
  }

  &__list {
    position: absolute;
    top: 86px;
    left: 16px;
    right: 16px;
    padding: 0.5rem 0;
    background: var(--white);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2),
      0px 10px 20px rgba(33, 52, 82, 0.2);
    border-radius: 6px;

    &-item {
      padding: 11px;
      font-size: 14px;
      line-height: 18px;

      &:hover {
        background: var(--bg-primary);
      }
    }
  }

  &__sum {
    flex: 1 0 auto;
  }
  @media (max-width: 1024px) {
    border-radius: 0;

    &__group {
      flex-direction: column;
    }
    &__payments {
      margin-bottom: 16px;
    }
    &__sum {
      width: 196px;
    }

    &__list {
      height: 150px;
      overflow: scroll;
    }
  }
}
</style>
