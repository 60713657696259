<template>
  <div class="drivers-form">
    <div class="drivers-form__grid">
      <UiDropdown
        label="Город"
        class="box-1"
        :list="cityList"
        v-model="driver.city"
        :has-error="!driver.city && validating"
      />

      <UiInput
        v-model="driver.surname"
        label="Фамилия"
        class="box-2"
        :has-error="!driver.surname && validating"
      />
      <UiInput
        v-model="driver.name"
        label="Имя"
        class="box-3"
        :has-error="!driver.name && validating"
      />
      <div v-if="!value">
        <p class="phone-number__label">Номер телефона</p>
        <input
          v-model="driver.phone"
          v-mask="'7 ### ### ## ##'"
          class="box-4 phone-number__container"
          :class="{
            'phone-number__container--error': !driver.phone && validating,
          }"
          style="width: 100%"
        />
        <span v-if="!driver.phone && validating" class="phone-number__error">
          Заполните поле
        </span>
      </div>
      <UiInput
        v-else
        v-model="driver.phone"
        :disabled="disabledFields.includes('phone')"
        label="Номер телефона"
        class="box-4"
        :has-error="!driver.phone && validating"
      />
      <UiInput
        v-model="driver.iin"
        label="ИИН"
        class="box-5"
        :max-length="12"
        :disabled="disabledFields.includes('iin')"
        :has-error="!driver.iin && validating"
      />
      <UiInput v-model="driver.comment" label="Комментарий" class="box-6" />
      <UiInput v-model="driver.details" label="Реквизиты" class="box-7" />
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import UiInput from "@/components/ui/UiInput.vue";
import UiDropdown from "@/components/ui/UiDropdown.vue";

export default {
  name: "DriversForm",
  directives: {
    mask,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    validating: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cityList: [{ id: 0, name: "Алматы" }],
      driver: {
        surname: null,
        name: null,
        phone: null,
        iin: null,
        comment: null,
        city: null,
        details: null,
      },
    };
  },
  components: {
    UiInput,
    UiDropdown,
  },
  created() {
    if (this.value) {
      this.driver.name = this.value.name;
      this.driver.surname = this.value.surname;
      this.driver.phone = this.value.phone_number;
      this.driver.iin = this.value.iin?.iin_bin;
      this.driver.comment = this.value.comment;
      this.driver.details = this.value.details;
    }
  },
  watch: {
    driver: {
      handler() {
        /* eslint-disable */
        // debugger;
        this.$emit("change", this.driver);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.drivers-form {
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background: var(--white);
  &__grid {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1em;
    grid-template-areas:
      "A A"
      "B C"
      "D E"
      "F F"
      "G G";
  }
  @media (max-width: 1024px) {
    border-radius: 0;
    &__grid {
      grid-template-areas:
        "A"
        "B"
        "C"
        "D"
        "E"
        "F"
        "G";
    }
  }
}
.box-1 {
  grid-area: A;
}
.box-2 {
  grid-area: B;
}
.box-3 {
  grid-area: C;
}
.box-4 {
  grid-area: D;
}
.box-5 {
  grid-area: E;
}
.box-6 {
  grid-area: F;
}
.box-7 {
  grid-area: G;
}

.phone-number {
  &__label {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
  }
  &__container {
    border: 1px solid var(--border);
    border-radius: 6px;
    padding: 11px;
    font-size: 14px;
    line-height: 18px;

    &--error {
      border: 1px solid var(--red);
    }
  }
  &__error {
    margin-top: 4px;
    font-size: 14px;
    line-height: 18px;
    color: var(--red);
  }
}
</style>
