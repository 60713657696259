<template>
  <div id="app">
    <CompanyNotFound v-if="isNotFound === 'true'" />
    <div v-else>
      <Sidemenu />
      <router-view v-if="loaded" class="main" />
    </div>
  </div>
</template>
<script>
import { getTokenByCode, isAuthorized } from "@/services/api/modules/auth";
import { mapActions, mapMutations } from "vuex";
import Sidemenu from "@/components/Sidemenu.vue";
import CompanyNotFound from "@/components/CompanyNotFound.vue";

export default {
  components: {
    Sidemenu,
    CompanyNotFound,
  },
  async created() {
    this.defineDevice();
    await this.init();
  },
  // async mounted() {
  //   await this.init();
  // },
  data() {
    return {
      isNotFound: window.sessionStorage.getItem("companyNotFound"),
      loaded: false,
    };
  },
  methods: {
    ...mapActions("user", {
      fetchUserProfile: "FETCH_USER_PROFILE",
    }),
    ...mapMutations({ setIsMobile: "device/SET_IS_MOBILE" }),
    async init() {
      try {
        const { code } = this.$route.query;
        if (!code) {
          console.log("redirect");
          return window.location.replace(
            `${process.env.VUE_APP_AUTH_SERVER}/oauth/login?redirect_uri=${process.env.VUE_APP_AUTH_REDIRECT_URI}&client_id=${process.env.VUE_APP_CLIENT_ID}`
          );
        }

        if (code) {
          await getTokenByCode(code);
          this.$router.replace({ ...this.$route.query, code: null });
        }
        return null;
      } finally {
        this.loaded = true;
        if (isAuthorized()) {
          await this.fetchUserProfile();
        }
      }
    },
    defineDevice() {
      if (window.innerWidth < 1025) {
        this.setIsMobile(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  float: right;
  width: calc(100% - 182px);
  // flex: 1 1 auto;
  padding: 24px 24px 20px 0px;

  @media (max-width: 1025px) {
    width: 100%;
    padding: 8px 0 0 0;
  }
}
</style>
