<template>
  <div class="sidemenu">
    <figure class="sidemenu__logo">
      <img v-if="!isMobile" src="@/assets/images/comsLogo.svg" alt="logo" />
      <img v-else src="@/assets/images/comsMobileLogo.svg" alt="logo" />
    </figure>

    <div class="sidemenu__group">
      <div class="sidemenu__nav">
        <div
          v-for="item in sideMenuConstants"
          :key="item.id"
          class="sidemenu__item"
          :class="{ 'sidemenu__item--selected': selectedRoute === item.route }"
          @click="onClick(item)"
        >
          <figure v-if="!isMobile" class="sidemenu__picture">
            <img :src="item.img" alt="side-item" class="sidemenu__img" />
          </figure>
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="sidemenu__exit" @click="onLogoutClick">
      <figure class="sidemenu__picture">
        <img src="@/assets/images/icons/side-signout.svg" alt="exit" />
      </figure>
      <span v-if="!isMobile"> Выйти </span>
    </div>
  </div>
</template>

<script>
import { logout } from "@/services/api/modules/auth";
import { mapGetters } from "vuex";

export default {
  name: "Sidemenu",
  data() {
    return {
      selectedRoute: null,
      sideMenuConstants: [
        /* eslint-disable global-require */
        {
          id: 1,
          name: "Водители",
          img: require("@/assets/images/icons/side-driver.svg"),
          route: "/",
        },
        {
          id: 2,
          name: "Выплаты",
          img: require("@/assets/images/icons/side-money.svg"),
          route: "/payments",
        },
        {
          id: 3,
          name: "Cводка",
          img: require("@/assets/images/icons/side-balance.svg"),
          route: "/summary",
        },
      ],
    };
  },
  created() {
    this.selectedRoute = this.$route.path;
  },
  computed: {
    ...mapGetters({ isMobile: "device/isMobile" }),
  },
  methods: {
    onClick(item) {
      if (this.selectedRoute !== item.route) {
        this.selectedRoute = item.route;
        this.$router.push(item.route);
      }
    },
    onLogoutClick() {
      logout()
        .then(() => {
          window.location.replace(
            `${process.env.VUE_APP_AUTH_SERVER}/oauth/logout`
          );
        })
        .catch(() => this.toast.show('Произошла ошибка при выходе с аккаунта'));
    },
  },
};
</script>

<style lang="scss" scoped>
.sidemenu {
  position: fixed;
  width: 182px;
  height: 100vh;

  &__logo {
    padding: 25px;
  }

  &__nav {
    padding: 8px 24px 8px 8px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 167px);
  }

  &__picture {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }
  &__img {
    object-fit: cover;
  }

  &__exit,
  &__item {
    display: flex;
    padding: 10px 16px;
    margin-bottom: 0.5rem;
    line-height: 20px;
    cursor: pointer;

    &:hover,
    &--selected {
      background: var(--bg-secondary);
      border-radius: 16px;
    }
  }

  &__exit {
    margin: 0px 24px 24px 8px;
  }

  @media (max-width: 1025px) {
    position: relative;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    height: auto;

    &__logo {
      background: #fff;
      padding: 13px 15px;
    }

    &__group {
      height: auto;
      background: #fff;
    }

    &__nav {
      display: flex;
      padding: 0 16px;
    }

    &__item {
      margin-bottom: 0;
      margin-right: 16px;
      padding: 12px 0;
      color: var(--secondary-dark);
      border-bottom: 3px solid #fff;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &--selected {
        color: var(--primary-dark);
        border-radius: 0;
        background: #fff;
        border-bottom: 3px solid var(--primary-dark);
      }
    }

    &__exit {
      position: absolute;
      top: 3px;
      right: 0;
      margin: 0;
      padding-right: 8px;

      &:hover {
        background: #fff;
      }
    }
  }
}
</style>
