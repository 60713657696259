import { render, staticRenderFns } from "./DriversList.vue?vue&type=template&id=c06409be&scoped=true&"
import script from "./DriversList.vue?vue&type=script&lang=js&"
export * from "./DriversList.vue?vue&type=script&lang=js&"
import style0 from "./DriversList.vue?vue&type=style&index=0&id=c06409be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c06409be",
  null
  
)

export default component.exports