export const PaymentsModule = {
  namespaced: true,
  state: {
    bulkPaymntsList: [],
    isAllPayments: false,
  },
  getters: {
    getBulkPaymentsList: (state) => state.bulkPaymntsList,
    getIsAllSelected: (state) => state.isAllPayments,
  },
  mutations: {
    SET_BULK_PAYMENTS_LIST(state, array) {
      state.bulkPaymntsList = array;
    },
    ADD_BULK_PAYMENT(state, id) {
      state.bulkPaymntsList.push(id);
    },
    DELETE_BULK_PAYMENT(state, id) {
      state.bulkPaymntsList.splice(
        state.bulkPaymntsList.indexOf(id),
        1
      );
    },
    SET_ALL_SELECTED(state, bool) {
      state.isAllPayments = bool;
    },
    SET_BULK_PAYMENTS(state, list) {
      state.bulkPaymntsList = list;
    }
  },
};
