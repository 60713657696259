<template>
  <div class="summary-finace">
    <h1 class="summary-finace__title">Финансы</h1>
    <UiQuestionIcon />
    <div class="summary-finace__card-wrap">
      <div class="summary-finace__card">
        <h2 class="summary-finace__card-title">Всего заработано</h2>
        <span class="summary-finace__card-value">{{ total }} ₸</span>
      </div>
      <div class="summary-finace__card">
        <h2 class="summary-finace__card-title">Доход таксопарка</h2>
        <span class="summary-finace__card-value">{{ income }} ₸</span>
      </div>
    </div>
    <div>
      <canvas id="summary-finance"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import UiQuestionIcon from "@/components/ui/UiQuestionIcon.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "SummaryFinances",
  components: {
    UiQuestionIcon,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    income: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getFinances: "summary/getFinances",
    }),
    chartData() {
      return {
        type: "line",
        data: {
          labels: this.getFinances.map((item) => {
            return dayjs(item.datetime).locale("ru").format("DD MMM");
          }),
          datasets: [
            {
              data: this.getFinances.map((item) => item.amount),
              borderColor: "#6FB9FD",
              backgroundColor: "#6eb9fd1a",
              fill: true,
            },
            {
              data: this.getFinances.map((item) => item.taxipark_amount),
              borderColor: "#444CF7",
              backgroundColor: "#444cf71a",
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: false,
            title: {
              display: true,
              text: "Chart.js Line Chart - Cubic interpolation mode",
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Value",
              },
              suggestedMin: -10,
              suggestedMax: 200,
            },
          },
        },
      };
    },
  },
  async mounted() {
    const ctx = document.getElementById("summary-finance");
    await new Chart(ctx, this.chartData);
  },
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.summary-finace {
  position: relative;
  &__title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }
  &__card {
    position: relative;
    width: 50%;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 6px;

    &:before {
      content: "";
      position: absolute;
      top: 16px;
      left: 16px;
      width: 20px;
      height: 20px;
      background: #444cf7;
      border-radius: 4px;
    }
    &:first-child {
      margin-right: 12px;

      &:before {
        background: #6fb9fd;
      }
    }

    &-title {
      margin-bottom: 8px;
      padding-left: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--secondary);
    }
    &-value {
      font-size: 24px;
      line-height: 30px;
      font-weight: 600;
    }
    &-wrap {
      display: flex;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 1025px) {
    &__card {
      width: 100%;
      &:first-child {
        margin-right: 0;
        margin-bottom: 8px;
      }
      &-wrap {
        flex-direction: column;
      }
    }
  }
}
</style>
