<template>
  <div>
    <FunctionalCalendar
      v-model="calendarData"
      :is-date-range="true"
      :is-multiple="true"
      :calendars-count="isMobile ? 1 : 2"
      :date-format="'dd/mm/yyyy'"
      :disabled-dates="['afterToday']"
      :hidden-elements="['leftAndRightDays']"
    />
    <div class="calendar__footer">
      <UiButton class="calendar__btn" @click="saveData">Применить</UiButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "CalendarDropdown",
  components: {
    UiButton,
  },
  props: {
    calendarMarkedDates: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      calendarData: this.calendarMarkedDates
        ? { dateRange: this.calendarMarkedDates }
        : {},
    };
  },
  computed: {
    ...mapGetters({ isMobile: "device/isMobile" }),
  },
  methods: {
    saveData() {
      this.$emit("changeRange", this.calendarData.dateRange);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  &__footer {
    align-self: flex-end;
    margin-top: 12px;
  }
  @media (max-width: 1024px) {
    &__btn {
      width: 100%;
      height: 50px;
    }
  }
}
</style>
