<template>
  <div v-if="!isMobile" class="payments">
    <div class="payments__header">
      <h1 class="title">Выплаты</h1>
      <!-- tabs -->
      <div class="payments__tab-wrap">
        <div class="payments__tab" @click="toExpectingPayments">
          планируемые
        </div>
        /
        <div class="payments__tab payments__tab--active">завершенные</div>
      </div>
    </div>
    <div class="payments__filters">
      <FilterPeriod @change="filterDate" />
      <UiSearchBar @change="searchDriverPayment" />
      <!-- <UiButton @click="showAddModal = true" class="payments__btn-add">
        <img
          src="@/assets/images/icons/add-white.svg"
          alt="add"
          class="payments__btn-img"
        />
        Добавить выплату
      </UiButton> -->
    </div>

    <UiLoader v-if="isLoading" class="payments__loader" />

    <div v-else-if="completedPayments.length">
      <div class="payments__list">
        <PaymentsCompletedList
          :list="completedPayments"
          :total-pages="paymentsTotalPages"
          @fetchMoreData="fetchMoreCompleted"
        />
        <UiLoader v-if="isMoreLoading" style="margin: 16px auto" />
      </div>
    </div>
    <div v-else class="payments__empty">Выплат пока нет</div>
    <UiModal v-if="showAddModal" @overlayClick="closeModal">
      <PaymentsAddModal @close="closeModal" />
    </UiModal>
  </div>

  <!-- Mobile version -->
  <div v-else>
    <div class="payments-mobile__header">
      <div class="payments-mobile__group">
        <h1 class="payments-mobile__title">Выплаты</h1>
        <!-- <UiButton @click="showAddModal = true">Добавить</UiButton> -->
      </div>
      <div class="payments-mobile__status" @click="showStatusModal = true">
        <p>Статус</p>
        <p class="payments-mobile__planned">Завершенные</p>
      </div>
      <div class="payments-mobile__period" @click="showDataPeriods = true">
        <p>Дата выплаты</p>
        <p class="payments-mobile__planned">
          {{ dayjs(selectedRange.start).locale("ru").format("DD MMM") }} -
          {{ dayjs(selectedRange.end).locale("ru").format("DD MMM") }}
        </p>
      </div>
    </div>

    <div class="payments-mobile__search">
      <UiSearchBar style="width: 100%" @change="searchDriverPayment" />
    </div>

    <UiLoader v-if="isLoading" class="payments__loader" />
    <div
      v-else-if="completedPayments.length"
      class="payments-mobile__card-wrap"
    >
      <PaymentsCardMobile
        v-for="pay in completedPayments"
        :key="pay.id"
        :payment-data="pay"
        :is-completed-payment="true"
        class="payments-mobile__card"
      />
    </div>
    <div
      v-if="completedPayments.length"
      v-observe-visibility="handleScrollToBottom"
    ></div>
    <UiLoader v-if="isMoreLoading" style="margin: 8px auto" />

    <UiModal
      v-if="showAddModal"
      title="Новая выплата"
      @overlayClick="closeModal"
    >
      <PaymentsAddModal @close="closeModal" @pay="payDriver" />
    </UiModal>

    <!-- mobile status change -->
    <UiModal
      v-if="showStatusModal"
      title="Тип"
      @overlayClick="showStatusModal = false"
    >
      <div class="payments-mobile-status">
        <div class="payments-mobile-status__item">
          <input
            id="completed"
            type="radio"
            :checked="routeName === '/payments/completed'"
          />
          <label for="completed" @click="routeName = '/payments/completed'"
            >Завершенные
          </label>
        </div>
        <div class="payments-mobile-status__item">
          <input
            id="expected"
            type="radio"
            :checked="routeName === '/payments'"
          />
          <label for="expected" @click="routeName = '/payments'"
            >Планируемые
          </label>
        </div>
        <div class="mobile-footer">
          <UiButton class="mobile-full-btn" @click="doRedirect"
            >Применить</UiButton
          >
        </div>
      </div>
    </UiModal>

    <!-- mobile period change -->
    <UiModal
      v-if="showDataPeriods"
      title="Период"
      @overlayClick="showDataPeriods = false"
    >
      <FilterPeriod @change="filterDate" @close="showDataPeriods = false" />
    </UiModal>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import { mapGetters } from "vuex";
import { dateFilters, getWeek, getToday } from "@/utils/constants/date-filter.constant";
import PaymentsCompletedList from "@/components/payments/PaymentsCompletedList.vue";
import PaymentsCardMobile from "@/components/payments/PaymentsCardMobile.vue";
import UiModal from "@/components/ui/UiModal.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiSearchBar from "@/components/ui/UiSearchBar.vue";
import PaymentsAddModal from "@/components/payments/PaymentsAddModal.vue";
import FilterPeriod from "@/components/FilterPeriod.vue";
import debounce from "debounce";
import dayjs from "dayjs";

export default {
  components: {
    UiSearchBar,
    UiButton,
    UiLoader,
    PaymentsCompletedList,
    PaymentsCardMobile,
    UiModal,
    PaymentsAddModal,
    FilterPeriod,
  },
  data() {
    return {
      showAddModal: false,
      completedPayments: [],
      paymentsTotalPages: 1,
      isLoading: true,
      showStatusModal: false,
      routeName: this.$route.path,
      payload: {
        page: 1,
        name: "",
        from: getWeek(),
        to: getToday(),
      },
      selectedRange: dateFilters[1],
      isMoreLoading: false,
      showDataPeriods: false,
    };
  },
  computed: {
    ...mapGetters({ isMobile: "device/isMobile" }),
  },
  created() {
    this.fetchCompletedPayments();
  },
  methods: {
    closeModal() {
      this.showAddModal = false;
    },
    toExpectingPayments() {
      this.$router.push("/payments");
    },
    filterDate(selected) {
      this.selectedRange = selected;
      this.payload.from = dayjs(selected.start).format("YYYY-MM-DD");
      this.payload.to = dayjs(selected.end).format("YYYY-MM-DD");
      this.clearList();
      this.fetchMoreCompleted(1);
    },

    searchDriverPayment: debounce(function searchDriverPayment(name) {
      this.clearList();
      this.payload.name = name;
      this.fetchCompletedPayments();
    }, 400),

    doRedirect() {
      this.$router.push(this.routeName).catch(() => {});
    },
    fetchMoreCompleted(page) {
      const isFetchMoreData = true;
      this.payload.page = page;
      this.fetchCompletedPayments(isFetchMoreData);
    },
    fetchCompletedPayments(isFetchMore) {
      if (isFetchMore) {
        this.isMoreLoading = true;
      } else {
        this.isLoading = true;
      }
      api.payments
        .getCompletedPayments(this.payload)
        .then((res) => {
          const list = this.completedPayments;
          this.completedPayments = list.concat(res.data?.items);
          this.paymentsTotalPages = res.data?.pagination?.total_pages;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.isLoading = false;
          this.isMoreLoading = false;
        });
    },
    payDriver() {
      this.showAddModal = false;
      this.fetchCompletedPayments();
    },
    clearList() {
      this.payload.name = "";
      this.payload.page = 1;
      this.completedPayments = [];
    },
    handleScrollToBottom(isVisible) {
      if (!isVisible || this.payload.page >= this.paymentsTotalPages) {
        return;
      }
      this.payload.page += 1;
      this.fetchMoreCompleted(this.payload.page);
    },
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.payments {
  &__header {
    display: flex;
  }

  &__tab {
    padding: 4px 8px;
    background: #c1cedd;
    border-radius: 100px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    &--active {
      color: var(--white);
      background: var(--primary-dark);
    }

    &-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      font-size: 24px;
      line-height: 31px;
      margin-left: 12px;
    }
  }

  &__filters {
    position: relative;
    display: flex;
    margin-bottom: 12px;
  }

  &__sort {
    margin-right: 0.5rem;
    z-index: 1;
  }

  &__btn {
    &-add {
      position: absolute;
      display: flex;
      top: 1px;
      right: 0;
    }
    &-img {
      position: relative;
      top: 2px;
      margin-right: 0.5rem;
    }
    &-xls {
      position: absolute;
      display: flex;
      align-items: center;
      top: 1px;
      right: 0;
      padding: 8px 12px;
      font-weight: 600;
      font-size: 14px;
    }
  }

  &__list {
    border-radius: 8px;
    background: var(--white);
    height: calc(100vh - 150px);
    overflow: scroll;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 148px);
    border-radius: 8px;
    background: var(--white);
    color: var(--secondary);
  }
  &__loader {
    margin: 300px auto;
    // position: fixed;
  }

  @media (max-width: 1025px) {
    &__loader {
      margin: 100px auto;
    }
  }
}
.payments-mobile {
  &-status {
    padding-top: 4px;
    background: #fff;

    &__item {
      padding: 12px 16px;
    }
  }
  &__header {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #dadfe5;
  }
  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
  }
  &__status {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #dadfe5;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
  }
  &__planned {
    position: relative;
    padding-right: 24px;
    color: #9ea6b4;

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      right: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/arrow-light-grey.svg");
      transform: translateY(-50%);
    }
  }

  &__period {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 9px;
    font-size: 14px;
    line-height: 18px;
  }
  &__filter {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 0 12px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    &:after {
      position: absolute;
      content: "";
      right: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/arrow-light-grey.svg") center /
        contain no-repeat;
    }
  }
  &__search {
    padding: 12px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__card {
    border-bottom: 1px solid #dadfe5;

    &:last-child {
      border-bottom: 0px;
    }
    &-wrap {
      padding: 4px 0;
      background: #ffffff;
      border-radius: 8px;
    }
  }
}
input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  & + label::before {
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--border);
    border-radius: 50%;
  }

  &:checked + label::before {
    opacity: 1;
    transform: scale(0.5);
    background: var(--green);
  }

  &:checked + label::after {
    position: absolute;
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--green);
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
