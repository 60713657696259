<template>
  <div class="drivers-list">
    <div class="drivers-list__filters">
      <div class="drivers-list__item drivers-list__item--name">Водитель</div>
      <div class="drivers-list__item drivers-list__item--payment">
        К выплате
      </div>
      <div class="drivers-list__item drivers-list__item--phone">Телефон</div>
      <div class="drivers-list__item drivers-list__item--iin">ИИН</div>
      <div class="drivers-list__item drivers-list__item--comment">
        Комментрий
      </div>
      <div class="drivers-list__item drivers-list__item--date">
        <div class="drivers-list__sort" @click="changeOrder">
          Дата добавления
          <img
            v-if="isSortUp"
            src="@/assets/images/icons/sort-up.svg"
            alt="sort-up"
            class="drivers-list__sort-img"
          />
          <img
            v-else
            src="@/assets/images/icons/sort-down.svg"
            alt="sort-down"
            class="drivers-list__sort-img"
          />
        </div>
      </div>
    </div>
    <div
      v-for="driver in drivers"
      :key="driver.id"
      class="drivers-list__listing"
      @click="showDetailsModal(driver.id)"
    >
      <div class="drivers-list__item drivers-list__item--name color-green">
        {{ driver.surname }} {{ driver.name }}
      </div>
      <div class="drivers-list__item drivers-list__item--payment">{{driver.amount}} ₸</div>
      <div class="drivers-list__item drivers-list__item--phone">
        {{ driver.phone_number }}
      </div>
      <div class="drivers-list__item drivers-list__item--iin">
        {{ driver.iin }}
      </div>
      <div class="drivers-list__item drivers-list__item--comment">
        {{ driver.comment }}
      </div>
      <div class="drivers-list__item drivers-list__item--date">
        {{ dayjs(driver.created_at).locale("ru").format("DD MMM YYYY, HH:mm") }}
      </div>
    </div>
    <div
      v-if="drivers.length"
      v-observe-visibility="handleScrollToBottom"
    ></div>
    <UiModal v-if="showDetails" @overlayClick="closeModal" is-bg-white>
      <DriversDetailsModal
        v-if="showDetails"
        :driverId="selectedDriverId"
        @close="closeModal"
        @driver-details-update="$emit('driver-details-update')"
      />
    </UiModal>
  </div>
</template>

<script>
import UiModal from "@/components/ui/UiModal.vue";
import DriversDetailsModal from "@/components/drivers/DriversDetailsModal.vue";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "DriversList",
  components: {
    UiModal,
    DriversDetailsModal,
  },
  props: {
    drivers: {
      type: Array,
      default: () => [],
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      showDetails: false,
      selectedDriverId: null,
      isSortUp: true,
      page: 1,
    };
  },
  methods: {
    dayjs,
    showDetailsModal(id) {
      this.selectedDriverId = id;
      this.showDetails = true;
    },
    closeModal() {
      this.showDetails = false;
    },
    changeOrder() {
      this.$emit("orderChange");
      this.isSortUp = !this.isSortUp;
    },
    handleScrollToBottom(isVisible) {
      if (!isVisible || this.page >= this.totalPages) {
        return;
      }
      this.page += 1;
      this.$emit("fetchMoreData", this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.drivers-list {
  &__filters {
    position: sticky;
    top: 0;
    display: flex;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: var(--secondary-dark);
    border-bottom: 1px solid var(--border);
    background: var(--white);
  }
  &__listing {
    padding: 23px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border);
    font-size: 14px;
    line-height: 18px;
    &:hover {
      background: var(--bg-primary);
      cursor: pointer;
    }
  }
  &__item {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }

    &--name {
      width: 20%;
    }
    &--payment {
      display: flex;
      justify-content: flex-end;
      width: 10%;
    }
    &--phone {
      width: 15%;
    }
    &--iin {
      width: 15%;
    }
    &--comment {
      width: 20%;
    }
    &--date {
      width: 17%;
    }
  }
  &__sort {
    position: relative;
    display: flex;
    color: var(--primary-dark);
    cursor: pointer;

    &-img {
      position: relative;
      top: -3px;
      right: -5px;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
