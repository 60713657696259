import Vue from 'vue';
import VueRouter from 'vue-router';
import Drivers from '../views/Drivers.vue';
import Payments from '../views/Payments.vue';
import PaymentsCompleted from '../views/PaymentsCompleted.vue';
import Summary from '../views/Summary.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Drivers',
    component: Drivers,
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
  },
  {
    path: '/payments/completed',
    name: 'PaymentsCompleted',
    component: PaymentsCompleted,
  },
  {
    path: '/summary',
    name: 'Summary',
    component: Summary,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
