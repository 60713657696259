<template>
  <div v-if="!isMobile" class="drivers">
    <h1 class="title">Водители</h1>
    <!-- if list is empty -->
    <div class="drivers__filters">
      <FilterPeriod @change="filterDate" />
      <UiSearchBar @change="searchDriver" />
      <UiButton @click="showAddModal = true" class="drivers__btn-add">
        <img
          src="@/assets/images/icons/add-white.svg"
          alt="add"
          class="drivers__btn-img"
        />
        Добавить водителя
      </UiButton>
    </div>
    <UiLoader v-if="isLoading" class="drivers__loader" />

    <div v-else-if="!driversList.length && !isSearching" class="drivers__empty">
      <p class="drivers__text">
        Добавьте водителей, чтобы у них появился доступ в курьерские приложения
        Raketa и Рядом.
      </p>
      <UiButton @click="showAddModal = true">Добавить водителя</UiButton>
    </div>

    <!-- list not empty -->
    <div v-else-if="driversList.length" class="drivers__list">
      <DriversList
        :drivers="driversList"
        :total-pages="driversTotalPages"
        @orderChange="changeOrder"
        @fetchMoreData="fetchMoreDrivers"
        @driver-details-update="clearList(); fetchDriversList();"
      />
      <UiLoader v-if="isMoreLoading" style="margin: 16px auto" />
    </div>

    <div v-else-if="!driversList.length && isSearching" class="drivers__empty">
      <p class="drivers__text">Таких водителей нет</p>
    </div>

    <UiModal v-if="showAddModal" @overlayClick="closeModal">
      <DriversAddModal @close="closeModal" @refreshList="fetchDriversList" />
    </UiModal>
  </div>
  <!-- Mobile version -->
  <div v-else>
    <div class="drivers-mobile__header">
      <div class="drivers-mobile__group">
        <div>
          <h1 class="drivers-mobile__title">Водители</h1>
          <p class="drivers-mobile__info">Баланс обновляется раз в час</p>
        </div>
        <UiButton @click="showAddModal = true">Добавить</UiButton>
      </div>
      <div
        class="flex justify-space-between drivers-mobile__filter"
        @click="showDataPeriods = true"
      >
        <p>Дата добавления</p>
        <div class="drivers-mobile__filter-date">
          {{ dayjs(selectedRange.start).locale("ru").format("DD MMM") }} -
          {{ dayjs(selectedRange.end).locale("ru").format("DD MMM") }}
        </div>
      </div>
    </div>
    <div class="drivers-mobile__search">
      <UiSearchBar style="width: 100%" @change="searchDriver" />
    </div>
    <UiLoader v-if="isLoading" class="drivers__loader" />
    <div v-else-if="driversList.length" class="drivers-mobile__card-wrap">
      <DriverCardMobile
        v-for="driver in driversList"
        :key="driver.id"
        :driver="driver"
      />
    </div>
    <div v-else-if="!driversList.length && isSearching" class="drivers__empty">
      <p class="drivers__text">Таких водителей нет</p>
    </div>

    <div
      v-if="driversList.length"
      v-observe-visibility="handleScrollToBottom"
    ></div>
    <UiLoader v-if="isMoreLoading" style="margin: 8px auto" />

    <UiModal
      v-if="showAddModal"
      title="Новый водитель"
      @overlayClick="closeModal"
    >
      <DriversAddModal @close="closeModal" @refresh-list="fetchDriversList" />
    </UiModal>
    <UiModal
      v-if="showDataPeriods"
      title="Период"
      @overlayClick="showDataPeriods = false"
    >
      <FilterPeriod @change="filterDate" @close="showDataPeriods = false" />
    </UiModal>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import { mapGetters } from "vuex";
import { dateFilters, getWeek, getToday } from "@/utils/constants/date-filter.constant";
import UiButton from "@/components/ui/UiButton.vue";
import UiModal from "@/components/ui/UiModal.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiSearchBar from "@/components/ui/UiSearchBar.vue";
import DriversAddModal from "@/components/drivers/DriversAddModal.vue";
import DriversList from "@/components/drivers/DriversList.vue";
import DriverCardMobile from "@/components/drivers/DriverCardMobile.vue";
import FilterPeriod from "@/components/FilterPeriod.vue";
import debounce from "debounce";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  components: {
    UiButton,
    UiModal,
    UiSearchBar,
    DriversAddModal,
    FilterPeriod,
    DriversList,
    DriverCardMobile,
    UiLoader,
  },
  data() {
    return {
      showAddModal: false,
      isLoading: true,
      isMoreLoading: false,
      payload: {
        page: 1,
        name: "",
        from: getWeek(),
        to: getToday(),
        sort_order: "date_desc",
      },
      driversList: [],
      isSearching: false,
      driversTotalPages: 1,
      showDataPeriods: false,
      selectedRange: dateFilters[1],
    };
  },
  created() {
    this.fetchDriversList();
  },
  computed: {
    ...mapGetters({
      isMobile: "device/isMobile",
    }),
    currentPeriod() {
      return this.selectedRange;
    },
  },
  methods: {
    closeModal() {
      this.showAddModal = false;
    },
    filterDate(selected) {
      this.clearList();
      this.isSearching = true;
      this.selectedRange = selected;
      this.payload.from = dayjs(selected.start).format("YYYY-MM-DD");
      this.payload.to = dayjs(selected.end).format("YYYY-MM-DD");
      this.fetchDriversList();
    },
    searchDriver: debounce(function searchDriver(name) {
      this.clearList();
      this.isSearching = true;
      this.payload.name = name;
      this.fetchDriversList();
    }, 400),

    fetchDriversList(isFetchMore) {
      if (isFetchMore) {
        this.isMoreLoading = true;
      } else {
        this.isLoading = true;
      }
      api.drivers
        .getDriversList(this.payload)
        .then((res) => {
          const list = this.driversList;
          this.driversList = list.concat(res.data?.items);
          this.driversTotalPages = res.data?.pagination?.total_pages;
        })
        .finally(() => {
          this.isMoreLoading = false;
          this.isLoading = false;
        });
    },
    changeOrder() {
      this.clearList();
      if (this.payload.sort_order === "date_asc") {
        this.payload.sort_order = "date_desc";
      } else {
        this.payload.sort_order = "date_asc";
      }
      this.fetchDriversList();
    },
    fetchMoreDrivers(page) {
      const isFetchMoreData = true;
      this.payload.page = page;
      this.fetchDriversList(isFetchMoreData);
    },
    clearList() {
      this.payload.page = 1;
      this.payload.name = "";
      this.driversTotalPages = 1;
      this.driversList = [];
      this.isLoading = true;
    },

    handleScrollToBottom(isVisible) {
      if (!isVisible || this.payload.page >= this.driversTotalPages) {
        return;
      }
      this.payload.page += 1;
      this.fetchMoreDrivers(this.payload.page);
    },
    dayjs,
  },
};
</script>
<style lang="scss" scoped>
.drivers {
  &__list {
    border-radius: 8px;
    background: var(--white);
    height: calc(100vh - 150px);
    overflow: scroll;
  }

  &__filters {
    position: relative;
    margin-bottom: 12px;
    display: flex;
  }

  &__empty {
    background: var(--white);
    border-radius: 8px;
    height: calc(100vh - 96px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__text {
    display: inline-block;
    max-width: 436px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    color: var(--secondary);
  }
  &__btn {
    &-add {
      position: absolute;
      display: flex;
      top: 1px;
      right: 0;
    }
    &-img {
      position: relative;
      top: 2px;
      margin-right: 0.5rem;
    }
  }
  &__loader {
    margin: 300px auto;
    // position: fixed;
  }

  @media (max-width: 1024px) {
    &__loader {
      margin: 150px auto;
    }
  }
}
.drivers-mobile {
  &__header {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__group {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }
  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
  }
  &__info {
    position: relative;
    padding-left: 16px;
    font-size: 12px;
    line-height: 14px;
    color: var(--secondary-dark);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 12px;
      height: 12px;
      background: url("~@/assets/images/icons/info.svg");
    }
  }
  &__filter {
    padding: 12px 0 9px;
    font-size: 14px;
    line-height: 18px;
    border-top: 1px solid #dadfe5;

    &-date {
      position: relative;
      color: #9ea6b4;
      padding-right: 24px;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 1rem;
        height: 1rem;
        background: url("~@/assets/images/icons/arrow-light-grey.svg");
        transform: translateY(-50%);
      }
    }
  }
  &__search {
    padding: 12px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__card {
    &-wrap {
      background: #ffffff;
      border-radius: 8px;
    }
  }
}
</style>
