<template>
  <div class="ui-input-checkbox-container">
    <label class="ui-input-checkbox">
      <input
        type="checkbox"
        :value="value"
        v-model="checkedNames"
        :disabled="disabled"
        @click="$emit('change')"
      />
      <span class="ui-input-checkbox__icon"></span>
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "UICheckbox",
  props: {
    array: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Number, Boolean],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkedNames: {
      get() {
        return this.array;
      },
      set(newVal) {
        return newVal;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-input-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &-container {
    position: relative;
    line-height: 1rem;

    input[type="checkbox"] {
      position: absolute;
      left: -9999px;
      visibility: hidden;
    }
  }

  &-container input[type="checkbox"]:checked + &__icon {
    &:before {
      border-color: var(--green);
      background: var(--green);
    }

    &:after {
      opacity: 1;
    }
  }

  &-container input[type="checkbox"]:disabled + &__icon {
    pointer-events: none;
    opacity: 0.5;
  }

  &__icon {
    position: relative;
    display: block;
    margin-right: 0.75rem;
    padding: 3px;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      border: 2px solid var(--border);
      border-radius: 3px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 9px;
      display: block;
      width: 6px;
      height: 10px;
      border-right: 3px solid var(--white);
      border-bottom: 3px solid var(--white);
      transform: rotate(45deg);
      opacity: 0;
      cursor: pointer;
      transition-property: background-color, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease;
    }
  }
}
</style>
