import { decryptJwt } from '@/utils/functions/decrypt-jwt';

export function user(client) {
  return {
    profile() {
      return client.get('api/v3/user')
        .then(response => response.data);
    },
    decryptJwt() {
      const { accessToken } = { ...client.context };
      return decryptJwt(accessToken);
    },
  };
}
