export function payments(client, xls) {
  return {
    // GET

    getPayments(payload) {
      let URL = `${process.env.VUE_APP_API_PREFIX}/v1/transaction/expect?page=${payload.page}`;

      if (payload.name) {
        URL += `&filter[driver]=${payload.name}`;
      }

      if (payload.from && payload.to) {
        URL += `&filter[date_from]=${payload.from}&filter[date_to]=${payload.to}&`;
      }

      if (payload.sort?.value) {
        URL += `&filter[amount_${payload.sort.trend}]=${payload.sort.value}`;
      }
      return client.get(URL)
        .then(response => response.data)
        .catch(err => err);
    },
    getCompletedPayments(payload) {
      let URL = `${process.env.VUE_APP_API_PREFIX}/v1/transaction/completed?page=${payload.page}&`;

      if (payload.name) {
        URL += `filter[driver]=${payload.name}&`;
      }

      if (payload.from && payload.to) {
        URL += `filter[date_from]=${payload.from}&filter[date_to]=${payload.to}&`;
      }

      return client.get(URL)
        .then(response => {
          return response.data;
        })
        .catch(err => err);
    },

    getXlsReport(payload) {
      const URL = `${process.env.VUE_APP_API_PREFIX}/v1/transaction/expect/report?filter[start_date]=${payload.from}&filter[end_date]=${payload.to}`;

      return xls.get(URL)
        .then(response => {
          return response.data;
        })
        .catch(err => err);
    },

    // POST

    payDriver(payload) {
      return client.post(`${process.env.VUE_APP_API_PREFIX}/v1/request/pay`, payload)
        .then(response => {
          return response.data;
        })
        .catch(err => err);
    },
    payBulk(payload) {
      return client.post(`${process.env.VUE_APP_API_PREFIX}/v1/request/pay/bulk`, payload)
        .then(response => {
          return response.data;
        })
        .catch(err => err);
    }
  };
}
