<template>
  <div class="ui-helper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UiHelper",
};
</script>

<style lang="scss" scoped>
.ui-helper {
  font-size: 16px;
  line-height: 20px;
  background: #2f3747;
  color: #fff;
  padding: 10px;
  box-shadow: 0px 6px 12px rgba(17, 24, 56, 0.15),
    0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
</style>
