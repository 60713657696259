<template>
  <div class="ui-loader"></div>
</template>

<script>
export default {
  name: "UiLoader",
};
</script>

<style lang="postcss">
.ui-loader {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.ui-loader:before,
.ui-loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 5px solid transparent;
  border-top-color: var(--bg-secondary);
}

.ui-loader:before {
  z-index: 50;
  animation: spin 1s infinite;
}

.ui-loader:after {
  border: 5px solid #fff;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
