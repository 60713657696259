import { render, staticRenderFns } from "./SummaryCouriers.vue?vue&type=template&id=5dc60855&scoped=true&"
import script from "./SummaryCouriers.vue?vue&type=script&lang=js&"
export * from "./SummaryCouriers.vue?vue&type=script&lang=js&"
import style0 from "./SummaryCouriers.vue?vue&type=style&index=0&id=5dc60855&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc60855",
  null
  
)

export default component.exports