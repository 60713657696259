<template>
  <div class="summary">
    <h1 v-if="!isMobile" class="title">Cводка</h1>
    <div>
      <div v-if="!isMobile" class="summary__filters">
        <FilterPeriod @change="filterDate" />
      </div>
      <!-- Mobile -->
      <div v-else class="summary-mobile__header">
        <div class="summary-mobile__group">
          <h1 class="summary-mobile__title">Cводка</h1>
        </div>
        <div class="summary-mobile__period" @click="showDataPeriods = true">
          <p>Период</p>
          <p class="summary-mobile__planned">
            {{ dayjs(selectedRange.start).locale("ru").format("DD MMM") }} -
            {{ dayjs(selectedRange.end).locale("ru").format("DD MMM") }}
          </p>
        </div>
      </div>
      <UiLoader v-if="isLoading" class="summary__loader" />
      <div v-else class="summary__list">
        <SummaryFinances
          :list="summaryFinances"
          :total="totalEarnings"
          :income="incomeAmount"
          class="summary__chart"
        />
        <SummaryCouriers :list="summaryCouriers" class="summary__chart" />
      </div>
      <UiModal
        v-if="showDataPeriods"
        title="Период"
        @overlayClick="showDataPeriods = false"
      >
        <FilterPeriod @change="filterDate" @close="showDataPeriods = false" />
      </UiModal>
    </div>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiModal from "@/components/ui/UiModal.vue";
import SummaryFinances from "@/components/summary/SummaryFinances.vue";
import SummaryCouriers from "@/components/summary/SummaryCouriers.vue";
import FilterPeriod from "@/components/FilterPeriod.vue";
import { getWeek, getToday } from "@/utils/constants/date-filter.constant";
import { mapGetters, mapMutations } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    FilterPeriod,
    SummaryCouriers,
    SummaryFinances,
    UiLoader,
    UiModal,
  },
  data() {
    return {
      summaryFinances: null,
      summaryCouriers: null,
      isLoading: true,
      totalEarnings: null,
      incomeAmount: null,
      selectedRange: "",
      payload: {
        from: getWeek(),
        to: getToday(),
      },
      showDataPeriods: false,
    };
  },
  computed: {
    ...mapGetters({ isMobile: "device/isMobile" }),
  },
  created() {
    this.fetchSummary();
  },
  methods: {
    ...mapMutations({
      setFinances: "summary/SET_FINANCES",
      setActiveCouriers: "summary/SET_ACTIVE_COURIERS",
    }),
    filterDate(selected) {
      this.selectedRange = selected;
      this.payload.from = dayjs(selected.start).format("YYYY-MM-DD");
      this.payload.to = dayjs(selected.end).format("YYYY-MM-DD");
      this.fetchSummary();
    },
    fetchSummary() {
      this.isLoading = true;
      api.summary
        .getSummary(this.payload)
        .then((res) => {
          this.totalEarnings = res.data?.total_amount;
          this.incomeAmount = res.data?.total_taxipark_amount;
          this.setFinances(res.data?.finances);
          this.setActiveCouriers(res.data?.active_couriers);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    dayjs,
  },
};
</script>
<style lang="scss" scoped>
.summary {
  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__chart {
    width: calc(50% - 10px);
    padding: 20px 24px;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  &__filters {
    position: relative;
    margin-bottom: 12px;
    display: flex;
  }

  &__empty {
    background: var(--white);
    border-radius: 8px;
    height: calc(100vh - 96px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__text {
    display: inline-block;
    max-width: 436px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    color: var(--secondary);
  }
  &__btn {
    &-add {
      position: absolute;
      display: flex;
      top: 1px;
      right: 0;
    }
    &-img {
      position: relative;
      top: 2px;
      margin-right: 0.5rem;
    }
  }
  &__loader {
    margin: 300px auto;
  }
  @media (max-width: 1185px) {
    &__list {
      flex-direction: column;
    }
    &__chart {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  @media (max-width: 1025px) {
    &__chart {
      padding: 16px 12px;
    }
    &__loader {
      margin: 100px auto;
    }
  }
}
.summary-mobile {
  &__header {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #dadfe5;
  }
  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
  }
  &__period {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 9px;
    font-size: 14px;
    line-height: 18px;
  }
  &__planned {
    position: relative;
    padding-right: 24px;
    color: #9ea6b4;

    &:after {
      position: absolute;
      content: "";
      top: 50%;
      right: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/arrow-light-grey.svg");
      transform: translateY(-50%);
    }
  }
}
</style>
