<template>
  <div class="payment-list">
    <div class="payment-list__header">
      <div class="payment-list__filters">
        <div class="payment-list__item payment-list__item--name">
          <UICheckbox
            :value="getIsAllSelected"
            @change="selectAll = !selectAll"
          >
            Водитель
          </UICheckbox>
        </div>
        <div class="payment-list__item payment-list__item--iin">ИИН</div>
        <div class="payment-list__item payment-list__item--payment">
          К выплате
        </div>
      </div>
      <div class="payment-list__total">
        <div class="payment-list__total-calc">
          <p>Всего</p>
          <p>{{ totalPayAmount }} ₸</p>
        </div>
      </div>
    </div>

    <div v-for="(item, i) in list" :key="i" class="payment-list__listing">
      <div class="payment-list__item payment-list__item--name color-green">
        <UICheckbox
          :array="getBulkPaymentsList"
          :value="item.employee_id"
          @change="$emit('bulkPayment', item)"
        >
          {{ item.employee_name }}
        </UICheckbox>
      </div>
      <div class="payment-list__item payment-list__item--iin">
        {{ item.employee_inn }}
      </div>
      <div class="payment-list__item payment-list__item--payment">
        {{ item.amount }} ₸
      </div>
      <div class="payment-list__item payment-list__item--action">
        <button class="payment-list__btn-pay" @click="showPayModal(item)">
          Выплатить
        </button>
      </div>
    </div>
    <div v-if="list.length" v-observe-visibility="handleScrollToBottom"></div>
  </div>
</template>

<script>
import "@/assets/styles/modules/payment-list.scss";
import UICheckbox from "@/components/ui/UiCheckbox.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PaymentsList",
  components: {
    UICheckbox,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectAll: false,
      page: 1,
    };
  },
  watch: {
    selectAll(val) {
      return val
        ? this.setBulkPaymentList(this.list.map((item) => item.employee_id))
        : this.setBulkPaymentList([]);
    },
  },
  computed: {
    ...mapGetters({
      getBulkPaymentsList: "payments/getBulkPaymentsList",
      getIsAllSelected: "payments/getIsAllSelected",
    }),
    totalPayAmount() {
      return this.list.reduce((total, cur) => {
        return +total + +cur.amount;
      }, 0);
    },
  },
  methods: {
    ...mapMutations({
      setBulkPaymentList: "payments/SET_BULK_PAYMENTS",
    }),
    showPayModal(item) {
      this.$emit("showModal", item);
    },
    handleScrollToBottom(isVisible) {
      if (!isVisible || this.page >= this.totalPages) {
        return;
      }
      this.page += 1;
      this.$emit("fetchMoreData", this.page);
    },
  },
};
</script>
