<template>
  <div class="payment-list">
    <div class="payment-list__header">
      <div class="payment-list__filters">
        <div class="payment-list__item payment-list__item--name">
          Номер платежа
        </div>
        <div class="payment-list__item payment-list__item--name">Водитель</div>
        <div class="payment-list__item payment-list__item--name">
          Сумма выплаты
        </div>
        <div class="payment-list__item payment-list__item--name">
          Способ оплаты
        </div>
        <div class="payment-list__item payment-list__item--name">
          Время выплаты
        </div>
      </div>
    </div>
    <div v-for="(item, i) in list" :key="i" class="payment-list__listing">
      <div class="payment-list__item payment-list__item--name color-green">
        №{{ item.transaction_id }}
      </div>
      <div class="payment-list__item payment-list__item--name">
        {{ item.employee_name }}
      </div>
      <div class="payment-list__item payment-list__item--name">
        {{ item.amount }} ₸
      </div>
      <div class="payment-list__item payment-list__item--name">-</div>
      <div class="payment-list__item payment-list__item--name">
        {{ dayjs(item.created_at).locale("ru").format("DD MMM YYYY, HH:mm") }}
      </div>
    </div>
    <div v-if="list.length" v-observe-visibility="handleScrollToBottom"></div>
  </div>
</template>

<script>
import "@/assets/styles/modules/payment-list.scss";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "PaymentsCompletedList",
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    dayjs,
    handleScrollToBottom(isVisible) {
      if (!isVisible || this.page >= this.totalPages) {
        return;
      }
      this.page += 1;
      this.$emit("fetchMoreData", this.page);
    },
  },
};
</script>
