<template>
  <div v-if="isActive" class="ui-tab">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "UiTab",
  props: {
    name: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>
