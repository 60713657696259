// import dayjs from "dayjs";

// require("dayjs/locale/ru");

export const FilterModule = {
  namespaced: true,
  state: {
    start: '',
    end: ''
  },
  getters: {
    getStartDate(state) {
      return state.start;
    },
    getEndDate(state) {
      return state.end;
    },
  },
  mutations: {
    SET_START_DATE(state, date) {
      state.start = date;
    },
    SET_END_DATE(state, date) {
      state.end = date;
    },
  },
};
