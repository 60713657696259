import { user } from './user';
import { drivers } from './drivers';
import { payments } from './payments';
import { summary } from './summary';
import { client, xls } from "../index";

export const api = {
  user: user(client),
  drivers: drivers(client),
  payments: payments(client, xls),
  summary: summary(client),
};
