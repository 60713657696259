export function summary(client) {
  return {
    getSummary(payload) {
      let URL = `${process.env.VUE_APP_API_PREFIX}/v1/report/summary`;

      if (payload.from && payload.to) {
        URL += `?filter[date_from]=${payload.from}&filter[date_to]=${payload.to}`;
      }

      return client.get(URL)
        .then(response => response.data)
        .catch(err => err);
    },
  };
}
