export const SummaryModule = {
  namespaced: true,
  state: {
    finances: null,
    active_couriers: null,
  },
  getters: {
    getFinances(state) {
      return state.finances;
    },
    getActiveCouriers(state) {
      return state.active_couriers;
    },
  },
  mutations: {
    SET_FINANCES(state, list) {
      state.finances = list;
    },
    SET_ACTIVE_COURIERS(state, list) {
      state.active_couriers = list;
    },
  },
};
