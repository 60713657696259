<template>
  <div class="ui-modal">
    <div class="ui-modal__cover" @click="$emit('overlayClick')"></div>
    <div class="ui-modal__content">
      <div class="ui-modal__header">
        <h2 class="ui-modal__title">{{ title }}</h2>
        <span class="ui-modal__cross" @click="$emit('overlayClick')" />
      </div>
      <transition name="slide-fade" mode="out-in">
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiModal",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  &__cover {
    background-color: #202333;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &__header {
    display: none;
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    width: 528px;
    height: 100%;
    z-index: 6;
    background-color: var(--bg-primary);
    overscroll-behavior: contain;
  }
  &__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 12px 20px;
    z-index: 7;
  }

  @media (max-width: 1024px) {
    &__content {
      top: auto;
      bottom: 0;
      width: 100%;
      height: auto;
      max-height: 92%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow-y: scroll;
    }
    &__header {
      display: block;
      position: sticky;
      top: 0;
      padding: 18px 16px 12px;
      background: #fff;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        background: #dadfe5;
        width: calc(100% - 32px);
      }
    }
    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      z-index: 1;
    }
    &__cross {
      position: absolute;
      top: 22px;
      right: 20px;
      width: 18px;
      height: 18px;
      background: url("~@/assets/images/icons/cross.svg");
      cursor: pointer;
      z-index: 1;
    }
  }
}
</style>
