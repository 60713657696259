export function drivers(client) {
  return {
    // GET

    getDriversList(payload) {
      let URL = `${process.env.VUE_APP_API_PREFIX}/v1/employees?page=${payload.page}&order=${payload.sort_order}&`;

      if (payload.name) {
        URL += `filter[driver]=${payload.name}&`;
      }

      if (payload.from && payload.to) {
        URL += `filter[date_from]=${payload.from}&filter[date_to]=${payload.to}`;
      }
      return client.get(URL)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          if (err.response?.data?.errors[0].detail === 'Таксопарк не найден') {
            window.sessionStorage.setItem('companyNotFound', true);
            window.location.reload();
          }
          return err.response;
        });
    },
    getDriverData(id) {
      return client.get(`${process.env.VUE_APP_API_PREFIX}/v1/employee/${id}`)
        .then(response => response.data)
        .catch(err => err);
    },
    getDriverTransactions(id) {
      return client.get(`${process.env.VUE_APP_API_PREFIX}/v1/transaction/employee/${id}`)
        .then(response => response)
        .catch(err => err.response);
    },

    // POST

    createDriver(payload) {
      return client.post(`${process.env.VUE_APP_API_PREFIX}/v1/employee/`, payload)
        .then(response => response)
        .catch(err => err.response);
    },

    // PUT

    updateDriver(payload) {
      return client.put(`${process.env.VUE_APP_API_PREFIX}/v1/employee/${payload.id}`, payload.data)
        .then(response => response.data)
        .catch(err => err.response);
    },
  };
}
