<template>
  <div class="card-transaction">
    <div class="flex justify-space-between">
      <p>Заказ №{{ item.id }}</p>
      <p :class="[isNegativeAmount ? 'color-red' : 'color-green']">
        <span v-if="!isNegativeAmount">+</span> {{ item.amount }} ₸
      </p>
    </div>
    <div class="flex justify-space-between color-grey">
      <p>{{ item.created_at.slice(11, 16) }}</p>
      <p>{{ item.account_name }} • Автомобиль</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTransaction",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isNegativeAmount() {
      return this.item.amount.toString().includes("-");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-transaction {
  padding: 1rem;
  background: var(--white);
  border-radius: 6px;
  font-size: 14px;
  line-height: 19px;

  @media (max-width: 1024px) {
    border: 1px solid #dadfe5;
  }
}
</style>
