// import { v4 as uuidv4 } from 'uuid';
import { setCookie, getCookie, removeCookie } from '@/utils/storage/cookies';
import { stringify } from 'qs';
import axios from 'axios';

const authClient = axios.create({
  baseURL: process.env.VUE_APP_CHOCO_GATEWAY,
  paramsSerializer: stringify,
  transformRequest: [
    data => stringify(data)
  ]
});

export const getTokenByCode = (code) => {
  return authClient.post('/auth/token', {
    client_id: process.env.VUE_APP_CLIENT_ID,
    grant_type: 'authorization_code',
    code,
    redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
    // 'X-Idempotency-key': uuidv4()
  }).then(res => {
    setCookie('auth::token', res.data.data.token);
    setCookie('auth::refresh-token', res.data.data.refresh_token);
    setCookie('auth::expire-in', res.data.data.expire_in);

    return '';
  }).catch(() => {
    return console.log('get token failed');
  });
};

export const logout = () => {
  removeCookie('auth::token');
  removeCookie('auth::refresh-token');
  removeCookie('auth::expire-in');
  window.sessionStorage.setItem('companyNotFound', false);
  return authClient.get('/auth/logout');
};

export const getTokenByRefresh = () => {
  console.log('Refresh');
  return authClient.post('/auth/token', {
    grant_type: 'refresh_token',
    client_id: process.env.VUE_APP_CLIENT_ID,
    refresh_token: getCookie('auth::refresh-token'),
  })
    .then((res) => {
      setCookie('auth::token', res.data.data.token);
      setCookie('auth::refresh-token', res.data.data.refresh_token);
      setCookie('auth::expire-in', res.data.data.expire_in);

      return res.data.data.token;
    });
};

export const isAuthorized = () => {
  return getCookie('auth::token');
};
