<template>
  <div v-if="!isMobile" class="payments">
    <div class="payments__header">
      <h1 class="title">Выплаты</h1>
      <!-- tabs -->
      <div class="payments__tab-wrap">
        <div class="payments__tab payments__tab--active">планируемые</div>
        /
        <div class="payments__tab" @click="toCompletedPayments">
          завершенные
        </div>
      </div>
    </div>
    <div class="payments__filters">
      <PaymentsSort class="payments__sort" @sort="sortPayments" />
      <FilterPeriod @change="filterDate" />
      <UiSearchBar @change="searchDriverPayment" />
      <!-- Возможно будет замена на отчеты -->
      <!-- <UiButton @click="addNewPayment" class="payments__btn-add">
        <img
          src="@/assets/images/icons/add-white.svg"
          alt="add"
          class="payments__btn-img"
        />
        Добавить выплату
      </UiButton> -->
      <UiButton
        @click="downloadXls"
        :is-grey="true"
        class="payments__btn-xls"
      >
        <img
            src="@/assets/images/icons/file-xls.svg"
            alt="xls"
            class="payments__btn-img"
        />
        Cкачать в XLSX
      </UiButton>
    </div>

    <UiLoader v-if="isLoading" class="payments__loader" />

    <div v-else-if="payments.length">
      <div class="payments__list">
        <PaymentsList
          :list="payments"
          :all-checked="isAllChecked"
          @showModal="showPaymentModal"
          @bulkPayment="toggleBulkPayment"
          @fetchMoreData="fetchMorePayments"
        />
        <UiLoader v-if="isMoreLoading" style="margin: 16px auto" />
      </div>
      <!-- bulk pay -->
      <div v-if="getBulkPaymentsList.length" class="payments__bulk-wrap">
        <p class="payments__bulk-amount">
          Выбрано водителей: {{ getBulkPaymentsList.length }}
        </p>
        <div class="payments__bulk-group">
          <UiButton class="payments__bulk-pay" @click="payBulk">
            Выплатить всем
          </UiButton>
          <UiButton class="payments__bulk-cancel" @click="cancelBulkPay">
            Отмена
          </UiButton>
        </div>
      </div>
    </div>

    <div v-else-if="!payments.length" class="payments__empty">
      Выплат пока нет
    </div>

    <UiModal v-if="showAddModal" @overlayClick="closeModal">
      <PaymentsAddModal
        :driver-pay-data="
          payments.find((item) => {
            return item.employee_id === selectedDriverId;
          })
        "
        @close="closeModal"
        @pay="payDriver"
      />
    </UiModal>
  </div>
  <!-- Mobile version -->
  <div v-else>
    <div class="payments-mobile__header">
      <div class="payments-mobile__group">
        <h1 class="payments-mobile__title">Выплаты</h1>
        <!-- Возможно будет скачать отчет -->
        <!-- <UiButton @click="addNewPayment">Добавить</UiButton> -->
      </div>
      <div class="payments-mobile__status" @click="showStatusModal = true">
        <p>Статус</p>
        <p class="payments-mobile__planned">Планируемые</p>
      </div>
      <div class="payments-mobile__filter" @click="showFilterModal = true">
        К Выплате
      </div>
    </div>
    <div class="payments-mobile__search">
      <UiSearchBar style="width: 100%" @change="searchDriverPayment" />
    </div>
    <UiLoader v-if="isLoading" class="payments__loader" />
    <div v-else-if="payments.length" class="payments-mobile__card-wrap">
      <PaymentsCardMobile
        v-for="pay in payments"
        :key="pay.id"
        :payment-data="pay"
        class="payments-mobile__card"
        @showModal="showPaymentModal"
        @bulkPayment="toggleBulkPayment"
      />
    </div>
    <div
      v-if="payments.length"
      v-observe-visibility="handleScrollToBottom"
    ></div>
    <UiLoader v-if="isMoreLoading" style="margin: 8px auto" />

    <!-- bulk pay -->
    <div v-if="getBulkPaymentsList.length" class="payments__bulk-wrap">
      <p class="payments__bulk-amount">
        Выбрано водителей: {{ getBulkPaymentsList.length }}
      </p>
      <div class="payments__bulk-group">
        <UiButton class="payments__bulk-pay" @click="payBulk"
          >Выплатить всем</UiButton
        >
        <UiButton class="payments__bulk-cancel" @click="cancelBulkPay"
          >Отмена</UiButton
        >
      </div>
    </div>
    <!-- mobile add modal -->
    <UiModal
      v-if="showAddModal"
      title="Новая выплата"
      @overlayClick="closeModal"
    >
      <PaymentsAddModal
        :driver-pay-data="
          payments.find((item) => {
            return item.employee_id === selectedDriverId;
          })
        "
        @close="closeModal"
        @pay="payDriver"
      />
    </UiModal>

    <!-- mobile status filter -->
    <UiModal
      v-if="showStatusModal"
      title="Тип"
      @overlayClick="showStatusModal = false"
    >
      <div class="payments-mobile-status">
        <div class="payments-mobile-status__item">
          <input
            id="completed"
            type="radio"
            :checked="routeName === '/payments/completed'"
          />
          <label for="completed" @click="routeName = '/payments/completed'"
            >Завершенные
          </label>
        </div>
        <div class="payments-mobile-status__item">
          <input
            id="expected"
            type="radio"
            :checked="routeName === '/payments'"
          />
          <label for="expected" @click="routeName = '/payments'"
            >Планируемые
          </label>
        </div>
        <div class="mobile-footer">
          <UiButton class="mobile-full-btn" @click="doRedirect"
            >Применить</UiButton
          >
        </div>
      </div>
    </UiModal>
    <!-- mobile filter modal -->
    <UiModal
      v-if="showFilterModal"
      title="Тип"
      @overlayClick="showFilterModal = false"
    >
      <PaymentsSort
        class="payments__sort"
        :is-mobile="true"
        @sort="sortPayments"
      />
    </UiModal>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import { mapGetters, mapMutations } from "vuex";
import PaymentsSort from "@/components/payments/PaymentsSort.vue";
import PaymentsList from "@/components/payments/PaymentsList.vue";
import PaymentsCardMobile from "@/components/payments/PaymentsCardMobile.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiModal from "@/components/ui/UiModal.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiSearchBar from "@/components/ui/UiSearchBar.vue";
import PaymentsAddModal from "@/components/payments/PaymentsAddModal.vue";
import FilterPeriod from "@/components/FilterPeriod.vue";
import debounce from "debounce";
import dayjs from "dayjs";
import { dateFilters, getToday, getWeek } from "@/utils/constants/date-filter.constant";

export default {
  components: {
    UiSearchBar,
    UiButton,
    UiLoader,
    UiModal,
    PaymentsSort,
    PaymentsList,
    PaymentsAddModal,
    PaymentsCardMobile,
    FilterPeriod,
  },
  data() {
    return {
      showAddModal: false,
      payments: [],
      isLoading: true,
      selectedDriverId: "",
      selectedDriverPaymentAmount: "",
      showStatusModal: false,
      showFilterModal: false,
      routeName: this.$route.path,
      isAllChecked: false,
      paymentsTotalPages: 1,
      payload: {
        page: 1,
        name: "",
        from: getWeek(),
        to: getToday(),
      },
      selectedRange: dateFilters[1],
      isMoreLoading: false,
    };
  },
  created() {
    this.fetchPayments();
  },
  computed: {
    ...mapGetters({
      isMobile: "device/isMobile",
      getBulkPaymentsList: "payments/getBulkPaymentsList",
    }),
    selectedBulkDrivers() {
      return this.payments.filter((item) =>
        this.getBulkPaymentsList.includes(item.employee_id)
      );
    },
  },
  methods: {
    ...mapMutations({
      deleteBulkPayment: "payments/DELETE_BULK_PAYMENT",
      addBulkPayment: "payments/ADD_BULK_PAYMENT",
      setBulkPaymentList: "payments/SET_BULK_PAYMENTS",
    }),

    toCompletedPayments() {
      this.$router.push("/payments/completed");
    },

    searchDriverPayment: debounce(function searchDriverPayment(name) {
      this.clearList();
      this.payload.name = name;
      this.fetchPayments();
    }, 400),

    sortPayments(sort) {
      this.clearList();
      this.showFilterModal = false;
      this.payload.sort = sort;
      this.fetchPayments();
    },

    addNewPayment() {
      this.selectedDriverId = "";
      this.showAddModal = true;
    },

    // Modal

    showPaymentModal(item) {
      this.selectedDriverId = item.employee_id;
      this.selectedDriverPaymentAmount = item.amount;
      this.showAddModal = true;
    },
    payDriver() {
      this.clearList();
      this.closeModal();
      this.fetchPayments();
    },
    closeModal() {
      this.showAddModal = false;
    },

    doRedirect() {
      this.$router.push(this.routeName).catch(() => {});
    },

    // Bulk payments

    toggleBulkPayment(pay) {
      if (this.getBulkPaymentsList.includes(pay.employee_id)) {
        this.deleteBulkPayment(pay.employee_id);
      } else {
        this.addBulkPayment(pay.employee_id);
      }
    },
    payBulk() {
      this.isLoading = true;
      api.payments
        .payBulk({
          payments: this.selectedBulkDrivers,
          payment_method_id: 1,
        })
        .then((res) => {
          this.cancelBulkPay();
          console.log(res);
        })
        .catch((err) => console.error(err))
        .finally(() => (this.isLoading = false));
    },
    cancelBulkPay() {
      this.setBulkPaymentList([]);
      this.isAllChecked = false;
    },

    // fetch Payments

    fetchPayments(isFetchMore) {
      if (isFetchMore) {
        this.isMoreLoading = true;
      } else {
        this.isLoading = true;
      }
      api.payments
        .getPayments(this.payload)
        .then((res) => {
          const list = this.payments;
          this.payments = list.concat(res.data?.items);
          this.paymentsTotalPages = res.data?.pagination?.total_pages;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.isLoading = false;
          this.isMoreLoading = false;
        });
    },

    fetchMorePayments(page) {
      const isFetchMoreData = true;
      this.payload.page = page;
      this.fetchPayments(isFetchMoreData);
    },

    // Update

    clearList() {
      this.payload.name = "";
      this.payload.page = 1;
      this.paymentsTotalPages = 1;
      this.payments = [];
    },

    handleScrollToBottom(isVisible) {
      if (!isVisible || this.payload.page >= this.paymentsTotalPages) {
        return;
      }
      this.payload.page += 1;
      this.fetchMorePayments(this.payload.page);
    },

    async downloadXls() {
      const { from, to } = this.payload;
      const payload = { from, to };
      await api.payments.getXlsReport(payload).then(res => {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });

        link.href = URL.createObjectURL(blob);
        link.download = 'report.xlsx';
        link.click();
      });
    },
    filterDate(selected) {
      this.selectedRange = selected;
      this.payload.from = dayjs(selected.start).format("YYYY-MM-DD");
      this.payload.to = dayjs(selected.end).format("YYYY-MM-DD");
      this.clearList();
      this.fetchMorePayments(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.payments {
  &__header {
    display: flex;
  }

  &__tab {
    padding: 4px 8px;
    background: #c1cedd;
    border-radius: 100px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    &--active {
      color: var(--white);
      background: var(--primary-dark);
    }

    &-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      font-size: 24px;
      line-height: 31px;
      margin-left: 12px;
    }
  }

  &__filters {
    position: relative;
    display: flex;
    margin-bottom: 12px;
    z-index: 2;
  }

  &__sort {
    margin-right: 0.5rem;
    z-index: 2;
  }

  &__btn {
    &-add {
      position: absolute;
      display: flex;
      top: 1px;
      right: 0;
    }
    &-img {
      position: relative;
      top: 2px;
      margin-right: 0.5rem;
    }
    &-xls {
      position: absolute;
      display: flex;
      align-items: center;
      top: 1px;
      right: 0;
      padding: 8px 12px;
      font-weight: 600;
      font-size: 14px;
    }
  }

  &__list {
    border-radius: 8px;
    background: var(--white);
    height: calc(100vh - 150px);
    overflow: scroll;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 148px);
    border-radius: 8px;
    background: var(--white);
    color: var(--secondary);
  }
  &__loader {
    margin: 300px auto;
  }

  &__bulk {
    &-wrap {
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 673px;
      padding: 10px;
      background: var(--primary-dark);
      color: #fff;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2),
        0px 10px 20px rgba(33, 52, 82, 0.2);
      border-radius: 8px;
    }
    &-group {
      display: flex;
    }
    &-amount {
      padding-left: 10px;
    }
    &-pay {
      background: #fff;
      color: var(--primary-dark);
      margin-right: 8px;
    }
    &-cancel {
      background: var(--border);
      color: var(--primary-dark);
    }
  }
  @media (max-width: 1025px) {
    &__loader {
      margin: 100px auto;
    }

    &__bulk {
      &-wrap {
        bottom: 12px;
        flex-direction: column;
        align-items: stretch;
        padding: 16px;
        width: 320px;
      }
      &-amount {
        padding-left: 0;
        margin-bottom: 12px;
        font-size: 18px;
      }
      &-group {
        flex-direction: row-reverse;
      }
      &-cancel {
        font-size: 14px;
        width: 130px;
      }
      &-pay {
        font-size: 14px;
        margin-right: 0;
        margin-left: 12px;
        width: 146px;
      }
    }
  }
}
.payments-mobile {
  &-status {
    padding-top: 4px;
    background: #fff;

    &__item {
      padding: 12px 16px;
    }
  }
  &__header {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #dadfe5;
  }
  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2px;
  }
  &__status {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #dadfe5;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
  }
  &__planned {
    position: relative;
    padding-right: 24px;
    color: #9ea6b4;

    &:after {
      position: absolute;
      content: "";
      right: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/arrow-light-grey.svg") center /
        contain no-repeat;
    }
  }
  &__filter {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 0 12px;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;

    &:after {
      position: absolute;
      content: "";
      right: 0;
      width: 16px;
      height: 16px;
      background: url("~@/assets/images/icons/arrow-light-grey.svg") center /
        contain no-repeat;
    }
  }
  &__search {
    padding: 12px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  &__card {
    border-bottom: 1px solid #dadfe5;

    &:last-child {
      border-bottom: 0px;
    }
    &-wrap {
      padding: 4px 0;
      background: #ffffff;
      border-radius: 8px;
    }
  }
}
input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  & + label::before {
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--border);
    border-radius: 50%;
  }

  &:checked + label::before {
    opacity: 1;
    transform: scale(0.5);
    background: var(--green);
  }

  &:checked + label::after {
    position: absolute;
    content: "";
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--green);
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
