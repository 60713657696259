<template>
  <div v-if="!isMobile" class="drivers-details-modal">
    <h1 class="drivers-details-modal__title">Водитель</h1>
    <span class="drivers-details-modal__cross" @click="$emit('close')" />
    <UiTabList>
      <UiTab name="Личные данные" :callback="getDriverData">
        <UiLoader v-if="isLoading" class="loader" />
        <div v-else class="drivers-details-modal__form">
          <DriversForm
            :disabled-fields="['iin']"
            :value="driverData"
            @change="changeDriver"
          />
        </div>
        <div class="drivers-details-modal__footer">
          <UiButton is-full @click="saveChanges">Сохранить</UiButton>
        </div>
      </UiTab>
      <UiTab name="Транзакции" :callback="getTransactions">
        <UiLoader v-if="isTransactionsLoading" class="loader" />
        <div
          v-else-if="!isTransactionsLoading && driverTransactions"
          class="drivers-details-modal__form"
        >
          <div
            v-for="(date, i) in driverTransactions"
            :key="i"
            class="drivers-details-modal__cards-wrap"
          >
            <div class="drivers-details-modal__date">
              {{ dayjs(i).locale("ru").format("DD MMM YYYY") }}
            </div>
            <CardTransaction
              v-for="item in date"
              :key="item.id"
              :item="item"
              class="drivers-details-modal__cards"
            />
          </div>
        </div>

        <p v-else class="drivers-details-modal__empty">
          Список транзакций пуст
        </p>
      </UiTab>
    </UiTabList>
  </div>
  <div v-else-if="isMobile && showTransactions" class="drivers-details-modal">
    <UiLoader v-if="isTransactionsLoading" class="loader" />
    <div
      v-else-if="!isTransactionsLoading && driverTransactions"
      class="drivers-details-modal__form"
    >
      <div
        v-for="(date, i) in driverTransactions"
        :key="i"
        class="drivers-details-modal__cards-wrap"
      >
        <div class="drivers-details-modal__date">
          {{ dayjs(i).locale("ru").format("DD MMM YYYY") }}
        </div>
        <CardTransaction
          v-for="item in date"
          :key="item.id"
          :item="item"
          class="drivers-details-modal__cards"
        />
      </div>
    </div>

    <p v-else class="drivers-details-modal__empty">Список транзакций пуст</p>
  </div>
  <div v-else-if="isMobile && !showTransactions">
    <UiLoader v-if="isLoading" class="loader" />
    <div v-else class="drivers-details-modal__form">
      <DriversForm
        :disabled-fields="['phone', 'iin']"
        :value="driverData"
        @change="changeDriver"
      />
    </div>
    <div class="drivers-details-modal__footer">
      <UiButton is-full @click="saveChanges">Сохранить</UiButton>
    </div>
  </div>
</template>

<script>
import { api } from "@/services/api/modules";
import UiTabList from "@/components/ui/tab/UiTabList.vue";
import UiTab from "@/components/ui/tab/UiTab.vue";
import UiButton from "@/components/ui/UiButton.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import CardTransaction from "@/components/card/CardTransaction.vue";
import DriversForm from "@/components/drivers/DriversForm.vue";
import dayjs from "dayjs";

require("dayjs/locale/ru");

export default {
  name: "DriversDetailsModal",
  components: {
    UiTabList,
    UiTab,
    UiButton,
    UiLoader,
    DriversForm,
    CardTransaction,
  },
  props: {
    driverId: {
      type: Number,
      default: null,
    },
    // for mobile
    isMobile: {
      type: Boolean,
      default: false,
    },
    showTransactions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: [],
      dateValue: "26 янв 2022",
      transactions: 3,
      driverData: null,
      driverTransactions: null,
      isLoading: true,
      isTransactionsLoading: false,
    };
  },
  created() {
    if (this.isMobile && this.showTransactions) {
      this.getTransactions();
    } else {
      this.getDriverData();
    }
  },
  methods: {
    dayjs,
    getDriverData() {
      api.drivers
        .getDriverData(this.driverId)
        .then((res) => {
          this.driverData = res.data;
        })
        .catch((err) => console.error(err))
        .finally(() => (this.isLoading = false));
    },
    getTransactions() {
      this.isTransactionsLoading = true;
      api.drivers
        .getDriverTransactions(this.driverId)
        .then((res) => {
          this.driverTransactions = res.data.items;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.isTransactionsLoading = false;
        });
    },
    saveChanges() {
      api.drivers
        .updateDriver({
          id: this.driverId,
          data: {
            city_id: 1,
            name: this.driverData.name,
            surname: this.driverData.surname,
            phone_number: this.driverData.phone,
            iin: this.driverData.iin,
            comment: this.driverData.comment,
            details: this.driverData.details || '',
          },
        })
        .then(() => {
          this.$emit("driver-details-update");
          this.$emit("close");
        })
        .catch((err) => console.error(err));
    },
    changeDriver(val) {
      this.driverData = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.drivers-details-modal {
  position: relative;
  &__title {
    font-size: 24px;
    line-height: 30px;
    padding: 25px 20px 16px 20px;
    background: var(--white);
  }
  &__cross {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 18px;
    height: 18px;
    background: url("~@/assets/images/icons/cross.svg");
    cursor: pointer;
  }
  &__form {
    padding: 12px 20px;
    overflow: scroll;
    height: calc(100vh - 106px);
  }
  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 528px;
    padding: 12px 20px;
    background: var(--white);
  }

  &__date {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0.5rem;
    color: var(--secondary);
  }
  &__cards {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
    &-wrap {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__empty {
    display: flex;
    justify-content: center;
    margin: 90px 0;
    color: var(--secondary);
  }

  @media (max-width: 1024px) {
    background: #fff;
    padding: 12px;

    &__cards {
      margin-bottom: 8px;
    }

    &__form {
      padding: 0;
      height: 100%;
    }
    &__footer {
      position: relative;
      padding: 12px;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2),
        0px -4px 10px rgba(33, 52, 82, 0.04);
      width: 100%;
    }
  }
}
</style>
